import React, { useState } from 'react';
import TopBar from '../components/top-bar/TopBar';
import { Box, Button, Pagination } from '@mui/material';
import { Stack } from '@mui/system';
import OfferLetterUploadModal from '../components/offer-letter-templates/modals/OfferLetterUploadModal';
import OfferLetterTemplateTable from '../components/offer-letter-templates/tables/OfferLetterTemplateTable';
import { useLoaderData } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import LetterSendDrawer from '../components/offer-letter-templates/LetterSendDrawer';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const OfferLettersPage = () => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const { offerLetterTemplates } = useLoaderData();
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const rowsPerPage = 3;

  const displayedRows = offerLetterTemplates.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  const totalPages = Math.ceil(offerLetterTemplates.length / rowsPerPage);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  // Drawer
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <TopBar title='Offer Letter Templates' user={user} />
      <Box flex={1}>
        <Box
          style={{
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
          }}
        >
          <Stack alignItems='flex-end' sx={{ margin: '32px 27px 0 0' }}>
            <Button
              onClick={() => setOpen(true)}
              startIcon={<AddOutlinedIcon />}
              variant='contained'
              sx={{
                height: '48px',
                borderRadius: '12px',
              }}
            >
              Add offer letter template
            </Button>
          </Stack>

          <Box marginX='28px' marginTop='28px'>
            <OfferLetterTemplateTable
              offerLetters={displayedRows}
              setSelectedRow={setSelectedRow}
              setOpenDrawer={setOpenDrawer}
            />
            <Box
              marginTop='28px'
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                variant='outlined'
                shape='rounded'
                color='primary'
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <OfferLetterUploadModal open={open} setOpen={setOpen} />

      <LetterSendDrawer
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        tableRow={selectedRow}
      />
    </>
  );
};

export default OfferLettersPage;
