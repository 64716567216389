import axios from 'axios';
import UrlRoute from '../configs/UrlRoute';

const TempAuthService = {
  setTempAuthToken: async (data) => {
    try {
      const baseURL = `${window.location.origin}`;
      const response = await axios.post(
        `${baseURL}${UrlRoute.SET_TEMP_AUTH}`,
        data,
      );
      return response;
    } catch (error) {
      console.error('Error in setTempAuthToken:', error);
      throw error;
    }
  },
};

export default TempAuthService;
