/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import Table from '../tables/Step1-Table';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styleProps } from '../../../styles/styleProps';
import { getPayrollEmployeeByEligibility } from '../../../services/EmployeeService';
import { useParams } from 'react-router-dom';
import {
  getAllPayRollsByYearAndMonth,
  getPayRollById,
} from '../../../services/PayRollService';
import { getCurrentMonthDateRange } from '../helpers/dateHelpers';

const { fonts } = styleProps;

function Step1(props) {
  const {
    selectedEmployees,
    setSelectedEmployees,
    setBulkData,
    bulkData,
    setErrorMessage,
  } = props;
  const urlParams = useParams();
  const payrollDisplayId = urlParams.payrollId;

  const { colors } = styleProps;
  const [employees, setEmployees] = useState([]);
  const [filteredData, setFilteredData] = useState(employees);
  const [searchValue, setSearchValue] = useState('');
  const [updateTableData, setUpdateTableData] = useState('');
  const [payrollId, setPayrollId] = useState(null);

  const { payrollStartDate, payrollEndDate, payDate } = bulkData;

  const toUTC = (date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
  };

  const { minDate, maxDate } = getCurrentMonthDateRange();

  useEffect(() => {
    if (payrollDisplayId) {
      getPayRollById(payrollDisplayId).then((res) => {
        if (res.success) {
          const data = res.message.payRoll;
          const payStubs = res.message.payStubs;

          setPayrollId(data.id);

          setBulkData((prev) => ({
            ...prev,
            payrollStartDate: new Date(data.startDate),
            payrollEndDate: new Date(data.endDate),
            payDate: new Date(data.payDate),
          }));
          setSelectedEmployees(
            new Set(payStubs.map((employee) => employee.employeeDisplayId)),
          );
        } else {
          window.history.pushState({}, null, '/payrollSchedule');
        }
      });
    }
  }, [payrollDisplayId]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredData(employees);
      return;
    }

    const filteredData = employees.filter((row) => {
      return (
        row.employeeEmployeeId.toLowerCase().includes(searchValue) ||
        row.employeeFirstName.toLowerCase().includes(searchValue) ||
        row.employeeLastName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filteredData);
  }, [searchValue, employees, updateTableData]);

  useEffect(() => {
    setErrorMessage('');

    if (payrollEndDate < payrollStartDate) {
      setErrorMessage('Payroll end date cannot be before payroll start date');
      setBulkData((prev) => ({ ...prev, payrollEndDate: null }));
      return;
    }
  }, [payrollEndDate, payrollStartDate]);

  const columns = [
    'Employee ID',
    'Name',
    'Email',
    'Designation',
    'Salary',
    'Mobile',
    'Paystub Status',
  ];

  useEffect(() => {
    getPyayrollEligibleEmployees();
  }, [updateTableData, payrollId]);

  const getPyayrollEligibleEmployees = async () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const res = await getPayrollEmployeeByEligibility(true);
    const payRollsRsponse = await getAllPayRollsByYearAndMonth(
      currentYear,
      currentMonth,
    );
    const payRolls = payRollsRsponse.message;

    const payRollIdsSet = new Set(payRolls.map((payroll) => payroll.id));

    const filteredEmployees = payrollDisplayId
      ? res.employees.filter((employee) =>
          employee.PayStubs.some(
            (payStub) =>
              payStub.payRollId === payrollId || payStub.payRollId == null,
          ),
        )
      : res.employees.filter((employee) =>
          employee.PayStubs.some(
            (payStub) =>
              payStub.payRollId == null ||
              !payRollIdsSet.has(payStub.payRollId),
          ),
        );

    setEmployees(filteredEmployees);
  };

  return (
    <Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', marginY: 3 }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  placeholder: 'Payroll start date',
                  sx: { ...CalenderStyle, width: '200px' },
                },
                inputAdornment: {
                  position: 'start',
                },
              }}
              slots={{
                openPickerIcon: CalendarMonthOutlinedIcon,
              }}
              value={payrollStartDate}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(newValue) =>
                setBulkData((prev) => ({
                  ...prev,
                  payrollStartDate: toUTC(newValue),
                }))
              }
              format='dd/MMM/yyyy'
            />
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  placeholder: 'Payroll end date',
                  sx: { ...CalenderStyle, width: '200px' },
                },
                inputAdornment: {
                  position: 'start',
                },
              }}
              slots={{
                openPickerIcon: CalendarMonthOutlinedIcon,
              }}
              minDate={payrollStartDate || minDate}
              maxDate={maxDate}
              value={payrollEndDate}
              onChange={(newValue) =>
                setBulkData((prev) => ({
                  ...prev,
                  payrollEndDate: toUTC(newValue),
                }))
              }
              format='dd/MMM/yyyy'
            />
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  placeholder: 'Pay date',
                  sx: { ...CalenderStyle, width: '180px' },
                },
                openPickerButton: {
                  sx: {
                    color: '#505057',
                  },
                },
                inputAdornment: {
                  position: 'start',
                },
              }}
              slots={{
                openPickerIcon: CalendarMonthOutlinedIcon,
              }}
              value={payDate}
              minDate={payrollStartDate || minDate}
              maxDate={maxDate}
              disablePast
              onChange={(newValue) =>
                setBulkData((prev) => ({ ...prev, payDate: toUTC(newValue) }))
              }
              format='dd/MMM/yyyy'
            />
          </LocalizationProvider>
        </Box>

        {/* Search bar */}
        <Box>
          <TextField
            size='small'
            value={searchValue}
            sx={{ gap: '12px', ...CalenderStyle, width: '220px' }}
            placeholder='Search employee'
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            InputProps={{
              startAdornment: (
                <SearchIcon
                  sx={{ color: colors.textLightGrey, marginRight: '12px' }}
                />
              ),
            }}
          />
        </Box>
      </Box>
      <Table
        data={filteredData}
        columns={columns}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
        setUpdateTableData={setUpdateTableData}
      />
    </Box>
  );
}

const CalenderStyle = {
  height: '48px',
  '& .MuiOutlinedInput-root': {
    height: '48px',
    borderRadius: '8px',
    border: '1px solid #BEBEC2',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    backgroundColor: '#FFFFFF',
    '& fieldset': {
      border: '1px solid #BEBEC2',
    },
    '&:hover fieldset': {
      border: '1px solid #BEBEC2',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #BEBEC2',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: fonts.primary,
    fontWeight: 400,
    fontSize: '16px',
    color: '#505057',
  },
};

export default Step1;
