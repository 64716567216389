import React from 'react';
import TopBar from '../components/top-bar/TopBar';
import { Box } from '@mui/material';
import { useAuth } from '../contexts/authContext';

import { ROLE_SUPER_ADMIN, ROLE_HR_MANAGER } from '../enums/User';
import OfferLetterTable from '../components/offer-letter/OfferLetterTable';
import { useLoaderData } from 'react-router-dom';
import {
  OFFER_LETTER_STATUS_APPROVED,
  OFFER_LETTER_STATUS_EXPIRED,
  OFFER_LETTER_STATUS_REJECTED,
  OFFER_LETTER_STATUS_SENT_FOR_APPROVAL,
  OFFER_LETTER_STATUS_SENT_FOR_SIGNATURE,
  OFFER_LETTER_STATUS_SIGNED,
} from '../constants/OfferLetter';

const OfferLettersPage = () => {
  const { user } = useAuth();
  const { offerLetters } = useLoaderData();
  const isSuperAdmin = user && user.adminRole === ROLE_SUPER_ADMIN;
  const isHRManager = user && user.adminRole === ROLE_HR_MANAGER;

  const toggleButtonValues = isSuperAdmin
    ? {
        all: {
          label: 'All',
        },
        [OFFER_LETTER_STATUS_SENT_FOR_APPROVAL]: {
          label: 'To Approve',
        },

        [OFFER_LETTER_STATUS_APPROVED]: {
          label: 'Approved',
        },
        [OFFER_LETTER_STATUS_REJECTED]: {
          label: 'Rejected',
        },
        [OFFER_LETTER_STATUS_SIGNED]: {
          label: 'Fully executed',
        },
      }
    : isHRManager
      ? {
          all: {
            label: 'All',
          },
          [OFFER_LETTER_STATUS_SENT_FOR_APPROVAL]: {
            label: 'Sent for approval',
          },
          [OFFER_LETTER_STATUS_APPROVED]: {
            label: 'Approved',
          },
          [OFFER_LETTER_STATUS_REJECTED]: {
            label: 'Rejected',
          },
          [OFFER_LETTER_STATUS_EXPIRED]: {
            label: 'Expired',
          },
          [OFFER_LETTER_STATUS_SENT_FOR_SIGNATURE]: {
            label: 'Sent for signature',
          },
          [OFFER_LETTER_STATUS_SIGNED]: {
            label: 'Fully executed',
          },
        }
      : [];

  return (
    <>
      <TopBar title='Offer Letters' user={user} />
      <Box flex={1}>
        <OfferLetterTable
          rows={offerLetters}
          toggleButtonValues={toggleButtonValues}
          adminRole={user?.adminRole}
        />
      </Box>
    </>
  );
};

export default OfferLettersPage;
