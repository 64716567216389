import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Divider,
} from '@mui/material';
import {
  KeyboardArrowDown as DropdownIcon,
  CardGiftcard as GiftIcon,
  Cake as CakeIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { getEmployeeByStatus } from '../../../services/EmployeeService';
import { EmployeeStatus } from '../../../enums/Employee';
import { styleProps } from '../../../styles/styleProps';

const { fonts } = styleProps;

// Utility to calculate upcoming birthdays
const calculateUpcomingBirthdays = (employees) => {
  const today = new Date();
  const currentYear = today.getFullYear();

  return employees
    .filter(({ employeeBirthDate }) => {
      if (!employeeBirthDate) return false;

      const birthDate = new Date(employeeBirthDate);

      const birthdayThisYear = new Date(
        currentYear,
        birthDate.getMonth(),
        birthDate.getDate(),
      );

      const daysDiff = Math.ceil(
        (birthdayThisYear - today) / (1000 * 3600 * 24),
      );

      // Handle edge case for year boundary
      if (daysDiff < 0) {
        const birthdayNextYear = new Date(
          currentYear + 1,
          birthDate.getMonth(),
          birthDate.getDate(),
        );
        const daysToNextBirthday = Math.ceil(
          (birthdayNextYear - today) / (1000 * 3600 * 24),
        );
        return daysToNextBirthday >= 0 && daysToNextBirthday <= 30;
      }

      return daysDiff >= 0 && daysDiff <= 30;
    })
    .map(({ employeeFirstName, employeeLastName, employeeBirthDate }) => {
      const birthDate = new Date(employeeBirthDate);
      let displayYear = currentYear;

      // Adjust the year for birthdays in the next year
      if (
        birthDate.getMonth() < today.getMonth() ||
        (birthDate.getMonth() === today.getMonth() &&
          birthDate.getDate() < today.getDate())
      ) {
        displayYear = currentYear + 1;
      }

      const birthday = new Date(
        displayYear,
        birthDate.getMonth(),
        birthDate.getDate(),
      );

      return {
        name: `${employeeFirstName} ${employeeLastName}`,
        birthday: birthday.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        }),
      };
    })
    .sort((a, b) => new Date(a.birthday) - new Date(b.birthday));
};

const UpcomingCelebrations = ({ cardStyle }) => {
  const [selectedCelebration, setselectedCelebration] =
    useState('celebration-a');
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { employees } = await getEmployeeByStatus(EmployeeStatus.ENABLED);
        const birthdays = calculateUpcomingBirthdays(employees || []);
        setUpcomingBirthdays(birthdays);
      } catch (error) {
        console.error('Error fetching data:', error);
        setUpcomingBirthdays([]);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    setselectedCelebration(event.target.value);
  };

  return (
    <Box sx={{ ...styles.container, ...cardStyle }}>
      {/* Header with dropdown */}
      <Box sx={styles.header}>
        <Typography sx={{ ...styles.heading }}>
          Upcoming Celebrations
        </Typography>
        <Box sx={styles.dropdownContainer}>
          <FormControl fullWidth>
            <Select
              value={selectedCelebration}
              onChange={handleChange}
              sx={styles.select}
              IconComponent={DropdownIcon}
              MenuProps={{
                PaperProps: {
                  sx: {
                    marginTop: '4px',
                    boxShadow:
                      '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
                  },
                },
              }}
            >
              <MenuItem value='celebration-a' sx={styles.menuItem}>
                Birthdays
              </MenuItem>
              <MenuItem value='celebration-b' sx={styles.menuItem}>
                Work anniversaries
              </MenuItem>
              <MenuItem value='celebration-c' sx={styles.menuItem}>
                Wedding anniversaries
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* content */}
      {selectedCelebration === 'celebration-a' &&
      upcomingBirthdays.length > 0 ? (
        <Box sx={styles.emptyStateContainer}>
          <Box sx={styles.MsgContainer}>
            {upcomingBirthdays.slice(0, 6).map(({ name, birthday }, index) => (
              <Box key={index}>
                <Box sx={styles.birthdayItem}>
                  <CakeIcon sx={styles.cakeIcon} />
                  <Box sx={styles.detailContainer}>
                    <Typography sx={styles.birthdayName}>{name}</Typography>
                    <Typography sx={styles.birthdayDate}>{birthday}</Typography>
                  </Box>
                </Box>
                {index !== birthday.length - 1 && (
                  <Divider sx={styles.divider} />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={styles.emptyStateContainer}>
          <Box sx={styles.emptyMsgContainer}>
            <Box sx={styles.iconContainer}>
              <GiftIcon sx={styles.giftIcon} />
            </Box>
            <Box>
              {selectedCelebration === 'celebration-a' && (
                <Typography sx={styles.emptyStateText}>
                  No upcoming Birthdays
                </Typography>
              )}
              {selectedCelebration === 'celebration-b' && (
                <Typography sx={styles.emptyStateText}>
                  No upcoming work anniversaries
                </Typography>
              )}
              {selectedCelebration === 'celebration-c' && (
                <Typography sx={styles.emptyStateText}>
                  No upcoming wedding anniversaries
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

UpcomingCelebrations.propTypes = {
  cardStyle: PropTypes.object,
};

export default UpcomingCelebrations;

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '301px',
    height: '22px',
    justifyContent: 'space-between',
    marginBottom: '18px',
  },
  heading: {
    fontFamily: fonts.primary,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#2F2F33',
    flexGrow: 1,
    minWidth: '185px',
    gap: '8px',
  },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px',
    minWidth: '108px',
  },
  select: {
    '& .MuiSelect-select': {
      fontSize: '14px',
      color: '#71717A',
      fontFamily: fonts.primary,
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingRight: '24px !important',
      backgroundColor: 'transparent',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '80px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      color: '#71717A',
    },
    overflow: 'hidden',
  },
  menuItem: {
    fontSize: '14px',
    fontFamily: fonts.primary,
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '383px',
    gap: '16px',
  },
  emptyMsgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
  MsgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
  iconContainer: {
    width: '48px',
    height: '48px',
  },
  giftIcon: {
    width: '100%',
    height: '100%',
    color: '#BEBEC2',
  },
  emptyStateText: {
    fontFamily: fonts.primary,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#A0A0A6',
  },
  birthdayItem: {
    width: '92%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '8px',
    gap: '12px',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  cakeIcon: {
    color: '#FF6B6B',
    fontSize: '32px',
  },
  birthdayName: {
    fontFamily: fonts.primary,
    fontWeight: 500,
    fontSize: '14px',
    color: '#2F2F33',
  },
  birthdayDate: {
    fontFamily: fonts.primary,
    fontSize: '14px',
    color: '#71717A',
  },
  divider: {
    width: '301px',
    height: 0,
    border: '1px solid #F1F1F2',
  },
};
