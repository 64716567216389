/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import ExpensesChart from '../charts/ExpensesChart';
import { styleProps } from '../../../styles/styleProps';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';

import payrollCalculations from '../helpers/calculations';
import { format } from 'date-fns';

const { fonts, colors } = styleProps;

function Step4({ bulkData }) {
  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
  const [data, setData] = useState({});

  const dataWidgetsList = [
    {
      icon: AccountBalanceWalletOutlinedIcon,
      text: `LKR ${nf.format(data.totalPayLKR)}`,
      subText: 'Total LKR Payment',
    },
    {
      icon: WalletOutlinedIcon,
      text: `USD ${nf.format(data.totalPayUSD)}`,
      subText: 'Total USD Payment',
    },
    {
      icon: PeopleAltOutlinedIcon,
      text: `${data.localCurrencyEmployeeCount} LKR / ${
        data.employeeCount - data.localCurrencyEmployeeCount
      } USD`,
      subText: 'Total employees',
    },
    {
      icon: CalendarMonthOutlinedIcon,
      text:
        format(new Date(bulkData.payrollStartDate), 'dd MMM yyyy') +
        ' - ' +
        format(new Date(bulkData.payrollEndDate), 'dd MMM yyyy'),
      subText: 'Payroll period',
    },
    {
      icon: DateRangeOutlinedIcon,
      text: format(new Date(bulkData.payDate), 'dd MMM yyyy'),
      subText: 'Pay date',
    },
  ];

  useEffect(() => {
    setData(payrollCalculations(bulkData));
  }, [bulkData]);

  return (
    <Box sx={styles.mainContaineer}>
      {/* Overview Container */}

      {/* Payroll Chart and Details */}
      <Box sx={{ display: 'flex', gap: 4 }}>
        {/* Payroll Chart */}
        <Box flex={5} sx={styles.roundedBorder}>
          <ExpensesChart calculatedData={data} />
        </Box>

        <Box
          flex={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {dataWidgetsList.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <Box
              flex={1}
              sx={[
                styles.roundedBorder,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                },
              ]}
            >
              {/* Icon Container */}
              <Box sx={{ ...styles.IconContainer }}>
                {/* Icon */}
                {React.createElement(item.icon, {
                  sx: { ...styles.Iconstyle },
                })}
              </Box>

              <Box sx={styles.dataWidgetTextContainer}>
                <CustomTypography noWrap variant='h6' fontWeight={600}>
                  {item.text}
                </CustomTypography>

                <CustomTypography
                  noWrap
                  variant='h8'
                  color={colors.textLightGrey}
                >
                  {item.subText}
                </CustomTypography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Step4;

const CustomTypography = styled(Typography)({
  fontFamily: fonts.primary,
});

const styles = {
  mainContaineer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '24px',
    marginBottom: '120px',
    marginTop: '60px',
  },
  overviewContainer: {
    display: 'flex',
    backgroundColor: '#0666EB1A',
    justifyContent: 'space-between',
    gap: '16px',
    paddingY: '8px',
    paddingX: '16px',
    borderRadius: '16px',
  },
  overviewParent: {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  roundedBorder: {
    borderRadius: '16px',
    border: '1px solid #F1F1F2',
    padding: '10px',
    boxShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },
  dataWidgetTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
  },
  IconContainer: {
    position: 'relative',
    width: '64px',
    height: '64px',
    background: '#F2F7FD',
    borderRadius: '16px',
    display: 'flex',
    marginX: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  Iconstyle: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    color: '#2F2F33',
  },
};
