import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styleProps } from '../styles/styleProps';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { useLoaderData, useParams } from 'react-router-dom';
import OfferLetterService from '../services/OfferLetterServices';
import { OFFER_LETTER_STATUS_SIGNED } from '../constants/OfferLetter';
import SignatureUpload from '../components/profile/SignatureUpload';
import GenericSection from '../components/generic/GenericSection';
import TempAuthService from '../services/TempAuthService';

import html3pdf from 'html3pdf';

const OfferLetterSignPage = () => {
  const urlParams = useParams();
  const offerLetterId = urlParams.id;

  const {
    offerLetter: initialOfferLetter,
    candidateEmail,
    signedBy,
  } = useLoaderData();

  const [offerLetterContent, setOfferLetterContent] =
    useState(initialOfferLetter);
  const [showSignatureUpload, setShowSignatureUpload] = useState(false);
  const [isSignatureUploaded, setIsSignatureUploaded] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  useEffect(() => {
    const setTempAuth = async () => {
      try {
        const response = await TempAuthService.setTempAuthToken({
          email: candidateEmail,
        });
        if (response?.headers?.['access-token']) {
          localStorage.setItem('tempAuth', response.headers['access-token']);
        }
      } catch (error) {
        console.error('Failed to set temporary authentication token:', error);
      }
    };

    if (candidateEmail) {
      setTempAuth();
    }
  }, [candidateEmail]);

  const updateOfferSign = async () => {
    await OfferLetterService.updateOfferLetter(
      offerLetterId,
      OFFER_LETTER_STATUS_SIGNED,
    );
    setSuccessMessage('You have successfully signed the offer letter.');
  };

  const fetchUpdatedOfferLetter = async () => {
    try {
      const updatedOfferLetter =
        await OfferLetterService.getOfferLetter(offerLetterId);

      setOfferLetterContent(updatedOfferLetter.letterContent);

      setShowDownloadButton(true);
    } catch (error) {
      console.error('Failed to fetch updated offer letter:', error);
    }
  };

  const handleSign = () => {
    if (!isSignatureUploaded) {
      setShowSignatureUpload(true);
    }
  };

  const handleSignatureComplete = async () => {
    setShowSignatureUpload(false);
    setIsSignatureUploaded(true);
    await updateOfferSign();
    await fetchUpdatedOfferLetter();
  };

  const downloadOfferLetterAsPDF = () => {
    const content = document.getElementById('offer-letter-content');

    const options = {
      margin: [15, 10, 15, 10],
      filename: 'OfferLetter.pdf',
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
    };

    html3pdf().from(content).set(options).save();
  };

  return (
    <GenericSection>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Box
          sx={{
            margin: 4,
            padding: '20px',
            backgroundColor: 'white',
            width: '800px',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {showSignatureUpload && (
              <SignatureUpload
                userEmail={candidateEmail}
                onComplete={handleSignatureComplete}
              />
            )}

            {!isSignatureUploaded && !signedBy ? (
              <PrimaryButton styles={signBtnStyle} onClick={handleSign}>
                Sign
              </PrimaryButton>
            ) : null}
          </Box>

          {successMessage && (
            <Box
              sx={{
                marginTop: 3,
                padding: 2,
                backgroundColor: '#e8f5e9',
                color: '#388e3c',
                borderRadius: '8px',
                textAlign: 'center',
              }}
            >
              {successMessage}
            </Box>
          )}

          {showDownloadButton || signedBy ? (
            <Box
              sx={{
                marginTop: 3,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <PrimaryButton
                onClick={downloadOfferLetterAsPDF}
                styles={{ marginBottom: '16px' }}
              >
                Download Offer Letter as PDF
              </PrimaryButton>
            </Box>
          ) : null}

          <div
            id='offer-letter-content'
            dangerouslySetInnerHTML={{ __html: offerLetterContent }}
            style={offerLetterContentStyle}
          />
        </Box>
      </Box>
    </GenericSection>
  );
};

export default OfferLetterSignPage;

const signBtnStyle = {
  fontFamily: styleProps.fonts.primary,
  fontSize: '16px',
  fontWeight: 500,
  minWidth: '160px',
  height: '50px',
  padding: '8px',
  borderRadius: '30px',
  textTransform: 'none',
  marginBottom: '24px',
};
const offerLetterContentStyle = {
  margin: '0 auto',
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
  lineHeight: '1.5',
  textAlign: 'justify',
  width: '100%',
  maxWidth: 'none',
  padding: '0',
  backgroundColor: '#ffffff',
};
