/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  Stack,
  Pagination,
  Box,
} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { styleProps } from '../../../styles/styleProps';
import EditModal from '../modals/EditModal';
import TooltipComponent from '../../tooltip/Tooltip';
import { EmployeeType } from '../../../enums/Employee';
import { paginate } from '../../../utils/PaginationUtils';
import { ITEMS_PER_PAGE } from '../../../constants/PaginationCount';

const { fonts, colors } = styleProps;

const TableComponent = ({ columns, data, bulkData, setBulkData }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalTitle, setEditModalTitle] = useState('');
  const [editModalData, setEditModalData] = useState('');
  const [editModalFieldId, setEditModalFieldId] = useState('');
  const [page, setPage] = useState(1);

  let nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const handleOpenEditModal = (title, data, fieldId) => {
    setEditModalTitle(title);
    setEditModalData(data);
    setEditModalFieldId(fieldId);
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setEditModalTitle('');
    setEditModalData('');
    setEditModalFieldId('');
    setShowEditModal(false);
  };

  const handleChangeValue = ({ fieldId, value, id }) => {
    setBulkData((prev) => {
      const employees = prev.employees;
      const employee = employees[id];
      employee[fieldId] = value;
      employees[id] = employee;
      return { ...prev, employees };
    });
    bulkData.employees[id].calculateEarningsAndDeductions();
  };

  // Use the pagination utility
  const { paginatedData, totalPages } = paginate(data, page, ITEMS_PER_PAGE);

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ marginBottom: '100px' }}>
      <StyledTableContainer component={Paper}>
        <StyledTable stickyHeader>
          <StyledTableHead>
            <TableRow sx={{ border: 'none' }}>
              {columns.map((headerName, index) => (
                <TableCell key={index} align='left'>
                  {headerName}
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {paginatedData.map((data, index) => (
              <React.Fragment key={index}>
                <StyledTableRow>
                  <TableCell align='left' sx={tableCellStyles}>
                    <TooltipComponent
                      tooltipText={
                        <>
                          <TooltipText>{`Type: ${data.employeeType}`}</TooltipText>
                          <TooltipText>{`Currency: ${data.employeeCurrencyCode}`}</TooltipText>
                        </>
                      }
                      tooltipData={`${data.employeeFirstName ?? ''} ${
                        data.employeeLastName ?? ''
                      }`}
                    />
                  </TableCell>

                  {[
                    {
                      title: 'Fuel & Maintenance',
                      field: 'employeeAllowanceFuel',
                    },
                    {
                      title: 'Traveling',
                      field: 'employeeAllowanceTravel',
                    },
                    {
                      title: 'Daily Paid',
                      field: 'employeeAllowanceDailyPay',
                    },
                    {
                      title: 'Over time',
                      field: 'employeeAllowanceOt',
                    },
                    {
                      title: 'Bonus',
                      field: 'employeeAllowanceBonus',
                    },
                    {
                      title: 'Reimbursement',
                      field: 'employeeReimbursements',
                    },
                  ].map((item, idx) => (
                    <TableCell align='left' sx={tableCellStyles} key={idx}>
                      {data.employeeType === EmployeeType.Permanent ||
                      data.employeeType === EmployeeType.Intern ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative',
                            '&:hover .edit-icon': {
                              opacity: 1,
                            },
                          }}
                        >
                          <Typography>{nf.format(data[item.field])}</Typography>
                          <CreateOutlinedIcon
                            className='edit-icon'
                            sx={{
                              cursor: 'pointer',
                              marginLeft: '8px',
                              fontSize: '16px',
                              opacity: 0,
                              transition: 'opacity 0.3s ease',
                              height: '24px',
                              width: '24px',
                            }}
                            onClick={() =>
                              handleOpenEditModal(item.title, data, item.field)
                            }
                          />
                        </Box>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                  ))}

                  <TableCell align='left' sx={tableCellStyles}>
                    <Typography>
                      {nf.format(
                        data.employeeType === EmployeeType.Permanent ||
                          data.employeeType === EmployeeType.Intern
                          ? data.employeeTotalEarnings
                          : data.employeeSalary,
                      )}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      <Stack spacing={2} alignItems='flex-end' sx={{ mt: 3, mb: 3 }}>
        <StyledPagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          shape='rounded'
        />
      </Stack>

      {/* Edit Modal for editing table data */}
      <EditModal
        visible={showEditModal}
        handleClose={handleModalClose}
        data={editModalData}
        fieldId={editModalFieldId}
        heading={'Update additional earning'}
        title={editModalTitle}
        onChange={(value) => {
          handleChangeValue({
            fieldId: editModalFieldId,
            id: editModalData.employeeDisplayId,
            value,
          });
        }}
      />
    </Box>
  );
};

// Styled Components
const StyledTableContainer = styled(Paper)({
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
});

const StyledTable = styled(Table)({
  minWidth: '1096px',
});

const StyledTableHead = styled(TableHead)({
  '& .MuiTableCell-head': {
    backgroundColor: '#F7F7F8',
    color: '#505057',
    fontWeight: 500,
    fontSize: '14px',
    padding: '12px 24px',
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
    },
    '&:last-child': {
      borderTopRightRadius: '8px',
    },
  },
});

const StyledTableRow = styled(TableRow)({
  height: '72px',
  backgroundColor: 'none',
  '&:hover': {
    backgroundColor: '#F5F7F8',
  },
  '& .MuiTableCell-body': {
    color: '#2D3A44',
    fontSize: '14px',
    padding: '16px 24px',
    maxHeight: '72px',
    position: 'relative',
  },
});

const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    color: '#505057',
    height: '40px',
    width: '40px',
    '&.Mui-selected': {
      backgroundColor: '#E6F0FD',
    },
  },
});

const tableCellStyles = {
  position: 'relative',
  fontFamily: fonts.primary,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: colors.textDarkGrey,
  '&:hover': {
    '& p': {
      opacity: '1',
    },
  },
};

const TooltipText = styled(Typography)({
  fontFamily: fonts.primary,
  fontSize: 13,
});

export default TableComponent;
