import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HolidayService from '../../services/HolidayService';
import {
  Box,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  Menu,
  MenuItem,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styleProps } from '../../styles/styleProps';
import format from 'date-fns/format';
import RenderHolidayList from './HolidayList';
import { startOfYear } from 'date-fns';
const { fonts } = styleProps;

const HolidaySettingsPanel = ({ isOpen, onClose }) => {
  const [selectedTab, setSelectedTab] = useState('add');
  const [holidayNameInputs, setHolidayNameInputs] = useState(['']);
  const [holidayDateInputs, setHolidayDateInputs] = useState([null]);
  const [holidays, setHolidays] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editingHoliday, setEditingHoliday] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchHolidays = async () => {
    try {
      setLoading(true);
      setError(null);

      // Get first day of current year
      const yearStart = format(startOfYear(new Date()), 'yyyy-MM-dd');
      const response = await HolidayService.getHolidays(yearStart);

      const holidayData = response.data;

      const processedHolidays = holidayData.map((holiday) => ({
        ...holiday,
        id: `${holiday.holidayDate}-${holiday.holidayName}`,
        name: holiday.holidayName,
        date: holiday.holidayDate,
      }));
      setHolidays(processedHolidays);
    } catch (error) {
      console.error('Error fetching holidays:', error);
      showSnackbar('Failed to fetch holidays.');
      setError('Failed to load holidays. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Helper function to check if a date already has a holiday
  const isDateHasHoliday = (dateToCheck) => {
    if (!dateToCheck) return false;

    const formattedDateToCheck = format(dateToCheck, 'yyyy-MM-dd');
    return holidays.some((holiday) => {
      const existingDate =
        holiday.date instanceof Date
          ? format(holiday.date, 'yyyy-MM-dd')
          : format(new Date(holiday.date), 'yyyy-MM-dd');
      return existingDate === formattedDateToCheck;
    });
  };

  useEffect(() => {
    if (isOpen) {
      setHolidayNameInputs(['']);
      setHolidayDateInputs([null]);
      setEditingHoliday(null);
      fetchHolidays();
    }
  }, [isOpen]);

  const handleDateChange = (index) => (newValue) => {
    if (newValue && isDateHasHoliday(newValue)) {
      showSnackbar(
        'A holiday already exists for this date. Please choose a different date.',
      );
      return;
    }
    const updatedDates = [...holidayDateInputs];
    updatedDates[index] = newValue;
    setHolidayDateInputs(updatedDates);
  };
  const handleNameChange = (index) => (e) => {
    const updatedNames = [...holidayNameInputs];
    updatedNames[index] = e.target.value;
    setHolidayNameInputs(updatedNames);
  };

  const handleAddNew = () => {
    setHolidayNameInputs([...holidayNameInputs, '']);
    setHolidayDateInputs([...holidayDateInputs, null]);
  };

  const handleClose = () => {
    onClose();
  };

  const handleMenuClick = (event, holiday) => {
    setAnchorEl(event.currentTarget);
    setEditingHoliday(holiday);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    if (editingHoliday) {
      setHolidayNameInputs([editingHoliday.name]);
      setHolidayDateInputs([new Date(editingHoliday.date)]);
      setSelectedTab('add');
      handleCloseMenu();
    }
  };

  const handleSaveEdit = async () => {
    if (!holidayNameInputs[0]?.trim() || !holidayDateInputs[0]) {
      showSnackbar('Please fill in all required fields');
      return;
    }

    try {
      setLoading(true);
      const formattedNewDate = format(holidayDateInputs[0], 'yyyy-MM-dd');

      // Check for existing holiday
      const dateHasOtherHoliday = holidays.some(
        (holiday) =>
          holiday.id !== editingHoliday.id &&
          format(new Date(holiday.date), 'yyyy-MM-dd') === formattedNewDate,
      );

      if (dateHasOtherHoliday) {
        showSnackbar(
          'A holiday already exists for this date. Please choose a different date.',
        );
        return;
      }

      const oldData = {
        holidayName: editingHoliday.name,
        holidayDate: format(new Date(editingHoliday.date), 'yyyy-MM-dd'),
      };

      const newData = {
        holidayName: holidayNameInputs[0].trim(),
        holidayDate: formattedNewDate,
        newHolidayName: holidayNameInputs[0].trim(),
        newHolidayDate: formattedNewDate,
      };

      if (
        oldData.holidayName === newData.holidayName &&
        oldData.holidayDate === newData.holidayDate
      ) {
        showSnackbar('No changes were made');
        return;
      }

      const result = await HolidayService.updateHoliday(oldData, newData);

      if (!result) {
        throw new Error('No response received from editHoliday');
      }

      if (result.success) {
        setHolidays((prevHolidays) =>
          prevHolidays.map((holiday) =>
            holiday.id === editingHoliday.id
              ? {
                  ...holiday,
                  name: newData.holidayName,
                  date: new Date(newData.holidayDate),
                  id: `${newData.holidayDate}-${newData.holidayName}`,
                }
              : holiday,
          ),
        );
        resetForm();
        showSnackbar('Holiday updated successfully!');
      } else {
        showSnackbar(result.error || 'Failed to update holiday');
      }
    } catch (error) {
      console.error('Error updating holiday:', error);
      showSnackbar(
        error.message || 'Failed to update holiday. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    handleCloseMenu();
    setEditingHoliday(null);
    setHolidayNameInputs(['']);
    setHolidayDateInputs([null]);
    setSelectedTab('view');
  };

  const handleDelete = async () => {
    if (editingHoliday) {
      try {
        const formattedDate = format(
          new Date(editingHoliday.date),
          'yyyy-MM-dd',
        );
        await HolidayService.deleteHoliday({
          holidayName: editingHoliday.name,
          holidayDate: formattedDate,
        });

        setHolidays((prevHolidays) =>
          prevHolidays.filter((holiday) => holiday.id !== editingHoliday.id),
        );
        handleCloseMenu();
        setEditingHoliday(null);
        showSnackbar('Holiday deleted successfully!');
      } catch (error) {
        console.error('Error deleting holiday:', error);
        showSnackbar('Failed to delete holiday. Please try again.');
      }
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isAddEnabled = () => {
    return holidayNameInputs[0] && holidayDateInputs[0] !== null;
  };

  const isPartiallyFilled = () => {
    return holidayNameInputs.some(
      (name, index) =>
        (name && !holidayDateInputs[index]) ||
        (!name && holidayDateInputs[index]),
    );
  };

  const handleSubmit = async () => {
    const newHolidays = holidayNameInputs
      .map((name, index) => ({
        holidayName: name,
        holidayDate: holidayDateInputs[index]
          ? format(holidayDateInputs[index], 'yyyy-MM-dd')
          : null,
      }))
      .filter((holiday) => holiday.holidayName && holiday.holidayDate);
    try {
      const createdHolidays = [];
      for (const holiday of newHolidays) {
        // Convert the date string to Date object for comparison
        const holidayDate = new Date(holiday.holidayDate);

        if (isDateHasHoliday(holidayDate)) {
          showSnackbar(
            `Cannot add holiday: ${holiday.holidayName}. A holiday already exists for ${holiday.holidayDate}`,
          );
          continue;
        }

        const response = await HolidayService.createHoliday(holiday);
        if (response.success) {
          createdHolidays.push({
            id: `${holiday.holidayDate}-${holiday.holidayName}`,
            name: holiday.holidayName,
            date: holiday.holidayDate,
          });
        } else {
          showSnackbar(
            response.message || 'Failed to add holiday: ' + holiday.holidayName,
          );
        }
      }

      setHolidays([...holidays, ...createdHolidays]);
      setHolidayNameInputs(['']);
      setHolidayDateInputs([null]);
      showSnackbar('Holidays added successfully!');
    } catch (error) {
      console.error('Error adding holidays:', error?.response?.data || error);
      showSnackbar('Failed to add holidays. Please try again.');
    }
  };

  return (
    <>
      {isOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={handleClose}
        />
      )}

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: isOpen ? 0 : '-100%',
          width: '620px',
          height: '100%',
          backgroundColor: '#FFFFFF',
          zIndex: 1000,
          boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.5)',
          transition: 'right 0.3s ease',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            borderBottom: '1px solid #979797',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              fontFamily: fonts.primary,
              color: '#000',
              marginBottom: '16px',
            }}
          >
            Holiday Settings
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            marginBottom: '30px',
            marginTop: '10px',
          }}
        >
          <Button
            onClick={() => setSelectedTab('add')}
            sx={{
              width: '310px',
              height: '44px',
              backgroundColor: selectedTab === 'add' ? '#E6F0FD' : 'inherit',
              borderRadius: '8px 0px 0px 8px',
              border: '1px solid var(--Secondary-Grey, #979797)',
              fontFamily: fonts.primary,
              fontWeight: 500,
              color: '#000',
              textTransform: 'none',
              opacity: selectedTab === 'add' ? 1 : 0.7,
              boxShadow: 'none',
            }}
          >
            Add Holidays
          </Button>
          <Button
            onClick={() => setSelectedTab('view')}
            sx={{
              width: '310px',
              height: '44px',
              backgroundColor: selectedTab === 'view' ? '#E6F0FD' : 'inherit',
              borderRadius: '0px 8px 8px 0px',
              border: '1px solid var(--Secondary-Grey, #979797)',
              fontFamily: fonts.primary,
              fontWeight: 500,
              color: '#000',
              textTransform: 'none',
              opacity: selectedTab === 'view' ? 1 : 0.7,
              boxShadow: 'none',
            }}
          >
            View Holidays
            {holidays.length > 0 && (
              <Typography
                sx={{
                  color: '#71717A',
                  fontSize: '14px',
                  marginLeft: '10px',
                }}
              >
                ({holidays.length})
              </Typography>
            )}
          </Button>
        </Box>

        {selectedTab === 'add' && (
          <Box>
            {holidayNameInputs.map((name, index) => (
              <Box
                display='flex'
                alignItems='center'
                gap={2}
                marginBottom={2}
                key={index}
              >
                <FormControl variant='outlined' sx={{ width: '300px' }}>
                  <InputLabel htmlFor={`holiday-name-${index}`}>
                    Holiday Name
                  </InputLabel>
                  <OutlinedInput
                    id={`holiday-name-${index}`}
                    value={name}
                    onChange={handleNameChange(index)}
                    label='Holiday Name'
                    sx={{
                      background: '#FFFFFF',
                      border: '1px #7F8489',
                      borderRadius: '5px',
                    }}
                  />
                </FormControl>
                <FormControl variant='outlined' sx={{ width: '300px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Select Date'
                      value={holidayDateInputs[index]}
                      onChange={handleDateChange(index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          sx={{
                            background: '#FFFFFF',
                            border: '1px solid #7F8489',
                            borderRadius: '8px',
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
            ))}

            <Box display='flex' alignItems='center' marginBottom={2}>
              <IconButton
                sx={{
                  background: '#0666EB',
                  width: '19.5px',
                  height: '19.5px',
                  borderRadius: '50%',
                  opacity: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleAddNew}
              >
                <AddIcon sx={{ color: 'white', fontSize: '14px' }} />
              </IconButton>
              <Typography
                sx={{
                  color: '#0666EB',
                  fontFamily: 'Readex Pro',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.17px',
                  marginLeft: '8px',
                }}
              >
                Add New
              </Typography>
            </Box>
          </Box>
        )}

        {selectedTab === 'view' && (
          <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
            <RenderHolidayList
              holidays={holidays}
              loading={loading}
              error={error}
              handleMenuClick={handleMenuClick}
            />
          </Box>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleEdit}>
            <EditOutlinedIcon sx={{ marginRight: 1 }} /> Edit
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <DeleteOutlineIcon sx={{ marginRight: 1 }} /> Delete
          </MenuItem>
        </Menu>

        {selectedTab === 'add' && (
          <Box
            display='flex'
            justifyContent='space-between'
            marginTop='auto'
            paddingBottom={5}
          >
            <Button
              variant='outlined'
              sx={{
                background: '#FFFFFF',
                border: '1px solid #8D8D95',
                width: '300px',
                height: '50px',
                borderRadius: '30px',
                opacity: 1,
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={!isAddEnabled() || isPartiallyFilled()}
              sx={{
                background: isAddEnabled() ? '#0666EB' : '#A0A0A0',
                color: '#FFFFFF',
                width: '300px',
                height: '50px',
                borderRadius: '30px',
              }}
              onClick={editingHoliday ? handleSaveEdit : handleSubmit}
            >
              {editingHoliday ? 'Save Changes' : 'Add'}
            </Button>
          </Box>
        )}

        {/* Snackbar for toast messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <SnackbarContent
            sx={{
              background: '#FEEDED',
              boxShadow: '0px 1px 1.41px 0px #00000033',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            message={
              <Typography variant='body2' color='black'>
                {snackbarMessage}
              </Typography>
            }
            action={
              <IconButton size='small' onClick={handleSnackbarClose}>
                <CloseIcon style={{ color: 'black' }} />
              </IconButton>
            }
          />
        </Snackbar>
      </Box>
    </>
  );
};

HolidaySettingsPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HolidaySettingsPanel;
