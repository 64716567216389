/**
 * Handles pagination logic.
 * @param {Array} data
 * @param {number} currentPage
 * @param {number} itemsPerPage
 * @returns {{ paginatedData: Array, totalPages: number }}
 */
export const paginate = (data, currentPage, itemsPerPage) => {
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);

  return { paginatedData, totalPages };
};
