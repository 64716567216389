import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

const RenderHolidayList = ({ holidays, loading, error, handleMenuClick }) => {
  if (loading) return <Typography>Loading holidays...</Typography>;
  if (error) return <Typography sx={{ color: 'red' }}>{error}</Typography>;
  if (!holidays || holidays.length === 0) {
    return <Typography>No holidays available.</Typography>;
  }

  // Sort holidays by date
  const sortedHolidays = [...holidays].sort((a, b) => {
    const dateA = new Date(a.date || a.Date);
    const dateB = new Date(b.date || b.Date);
    return dateA - dateB;
  });

  return sortedHolidays.map((holiday) => {
    // Add date validation
    const dateObj = new Date(holiday.date || holiday.Date);
    const formattedDate = isNaN(dateObj.getTime())
      ? '-'
      : format(dateObj, 'dd MMM yyyy');
    const formattedDay = isNaN(dateObj.getTime())
      ? '-'
      : format(dateObj, 'EEEE');

    return (
      <Box
        key={holiday.id}
        sx={{ borderBottom: '1px solid #ddd', padding: '10px 0' }}
      >
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Typography variant='subtitle1'>
              {holiday.name || holiday.holidayName}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <Box sx={{ textAlign: 'right', mr: 1 }}>
              <Typography>{formattedDate}</Typography>
              <Typography variant='caption' color='textSecondary'>
                {formattedDay}
              </Typography>
            </Box>
            <IconButton
              onClick={(e) => handleMenuClick(e, holiday)}
              sx={{ color: '#30A3F7' }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  });
};

RenderHolidayList.propTypes = {
  holidays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      holidayName: PropTypes.string,
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      Date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      employees: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  handleMenuClick: PropTypes.func.isRequired,
};

RenderHolidayList.defaultProps = {
  loading: false,
  error: null,
  holidays: [],
};
export default RenderHolidayList;
