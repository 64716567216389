export const styleProps = {
  fonts: {
    primary: 'Inter',
  },
  fontSizes: {
    header: '28px',
    normal: '14px',
  },
  colors: {
    btnPrimary: '#0666EB',
    btnSecondary: '#0666EB33',
    btnWarning: '#F2484B',
    backgroundLow: '#0666EB1A',
    textPrimary: '#0666EB',
    textDarkGrey: '#2A2A2A',
    textMediumGrey: '#505057',
    textLightGrey: '#71717A',
    textButton: '#2F2F33',
    textGreen: '#00909E',
    textRed: '#EA5455',
    primaryBlue: '#0666EB',
    white: '#FFFFFF',
    black: '#000000',
    whitenew: '#F1F1F2',
  },
  projectsContainer: {
    position: 'relative',
    width: '48%',
    border: '2px solid #DEEBFC',
    borderRadius: 4,
    margin: '.5%',
  },
  projectReportContainer: {
    position: 'relative',
    width: '100%',
    border: '2px solid #DEEBFC',
    borderRadius: 4,
  },
};
