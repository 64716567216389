import React from 'react';
import { getCurrentAdmin } from '../services/AdminService';
import TopBar from '../components/top-bar/TopBar';
import { Alert, Box, Button, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import Stepper from '../components/payroll-schedule/Stepper';
import payrollsConstants from '../enums/Payrolls';
import { savePayroll } from '../services/PayRollService';
import calculations from '../components/payroll-schedule/helpers/calculations';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import {
  Step1,
  Step2,
  Step3,
  Step4,
} from '../components/payroll-schedule/steps';
import { useParams } from 'react-router-dom';

const Payroll = () => {
  const urlParams = useParams();
  const payrollId = urlParams.payrollId;
  const [user, setUser] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState(new Set());
  const [bulkData, setBulkData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const errorAlertStatus = Boolean(errorMessage);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setUser(await getCurrentAdmin());
  };

  const handleNext = async () => {
    setErrorMessage('');
    const { payrollStartDate, payrollEndDate, payDate } = bulkData;

    if (!payrollStartDate || !payrollEndDate || !payDate) {
      setErrorMessage('Please fill out all date fields');
      return;
    }

    if (!selectedEmployees.size) {
      setErrorMessage('Please select at least one employee');
      return;
    }

    if (activeStep === 3) {
      await handleFinish();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleFinish = async () => {
    setSaving(true);
    const { totalPay, totalPayLKR, totalPayUSD } = calculations(bulkData);
    const bulkDataCopy = { ...bulkData };
    const employeeArray = Object.values(bulkData.employees);
    bulkDataCopy.employees = employeeArray;
    const data = {
      ...bulkDataCopy,
      status: payrollsConstants.PayrollStatus.SCHEDULED,
      payrollId: payrollId || '', // payrollId empty means new
      totalPay,
      totalPayLKR,
      totalPayUSD,
      currencyCode: 'LKR',
    };

    await savePayroll(data)
      .then((res) => {
        if (res.success) {
          window.location.href = '/payroll';
        }
      })
      .catch(() => {})
      .finally(() => {
        setSaving(false);
      });
  };

  const handleSaveDraft = async () => {
    setSaving(true);
    const { totalPay, totalPayLKR, totalPayUSD } = calculations(bulkData);
    const bulkDataCopy = { ...bulkData };
    const employeeArray = Object.values(bulkData.employees);
    bulkDataCopy.employees = employeeArray;
    const data = {
      ...bulkDataCopy,
      status: payrollsConstants.PayrollStatus.DRFT,
      payrollId: payrollId || '', // payrollId empty means new
      totalPay,
      totalPayLKR,
      totalPayUSD,
      currencyCode: 'LKR',
    };

    await savePayroll(data)
      .then((res) => {
        if (res) {
          window.location.href = '/payroll';
        }
      })
      .catch(() => {})
      .finally(() => {
        setSaving(false);
      });
  };

  const handleCancel = () => {
    window.location.href = '/payroll';
  };

  const isCurrentMonthAndYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const { payrollStartDate } = bulkData;

    return (
      payrollStartDate &&
      payrollStartDate.getFullYear() === currentYear &&
      payrollStartDate.getMonth() === currentMonth
    );
  };

  const renderStep = (activeStep, selectedEmployees, bulkData, setBulkData) => {
    switch (activeStep) {
      case 0:
        return (
          <Step1
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            bulkData={bulkData}
            setBulkData={setBulkData}
            setErrorMessage={setErrorMessage}
          />
        );
      case 1:
        return (
          <Step2
            selectedEmployees={selectedEmployees}
            bulkData={bulkData}
            setBulkData={setBulkData}
          />
        );
      case 2:
        return <Step3 bulkData={bulkData} setBulkData={setBulkData} />;
      default:
        return <Step4 bulkData={bulkData} setBulkData={setBulkData} />;
    }
  };

  return (
    <>
      <TopBar title='Payroll > Schedule Payroll' user={user} />
      <Box flex={1}>
        <Box
          style={{
            maxHeight: 'calc(100vh - 64px)',
            overflowY: 'auto',
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <Stepper activeStep={activeStep}>
            {renderStep(activeStep, selectedEmployees, bulkData, setBulkData)}
          </Stepper>

          <Box sx={styles.fixedFooter}>
            <Button
              sx={styles.buttonStyle}
              color='error'
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Box sx={{ marginRight: 'calc(24vw - 2.5%)' }}>
              <Button
                disabled={saving || activeStep === 0}
                color='primary'
                sx={styles.buttonStyle}
                onClick={handleSaveDraft}
              >
                Save as Draft
              </Button>

              {activeStep !== 0 && (
                <Button
                  variant='outlined'
                  sx={{
                    ...styles.buttonStyle,
                    ...styles.InvertedButtonStyle,
                    color: '#2F2F33',
                  }}
                  onClick={handleBack}
                  startIcon={<KeyboardBackspaceOutlinedIcon />}
                >
                  Previous
                </Button>
              )}

              <Button
                disabled={
                  saving ||
                  selectedEmployees.size === 0 ||
                  !isCurrentMonthAndYear()
                }
                variant='contained'
                sx={styles.buttonStyle}
                color='primary'
                onClick={handleNext}
                endIcon={activeStep === 3 ? null : <EastOutlinedIcon />}
              >
                {activeStep === 3 ? 'Schedule' : 'Next'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={errorAlertStatus}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert
          severity='error'
          sx={{ width: '100%' }}
          onClose={() => setErrorMessage('')}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Payroll;

const styles = {
  fixedFooter: {
    borderTop: '1px solid #E5E5E5',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    height: 80,
    zIndex: 100,
    backgroundColor: '#fff',
  },
  buttonStyle: {
    margin: '0 .4rem',
    borderRadius: '12px',
    fontWeight: '600',
    paddingX: 3,
    paddingY: 1,
    height: '40px',
  },
  InvertedButtonStyle: {
    backgroundColor: '#fff',
    border: '2px solid #A0A0A6',
    '&:hover': {
      border: '2px solid #0666EB',
    },
  },
};
