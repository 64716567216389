import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import MenuItem from './menu-item/MenuItem';
import LogoImage from '../../assets/images/NimiKashLogo.svg';
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_DELIVERY_MANAGER,
  ROLE_HR_MANAGER,
} from '../../enums/User';
import { styleProps } from '../../styles/styleProps';
import {
  superAdminMenuItems,
  adminMenuItems,
  deliveryManagerMenuItems,
  hrManagerMenuItems,
} from '../../data/genericConstants';

const { colors } = styleProps;

const SideNavbar = ({ user }) => {
  const getMenuItemsByRole = (role) => {
    const menuItemsMap = {
      [ROLE_ADMIN]: adminMenuItems,
      [ROLE_SUPER_ADMIN]: superAdminMenuItems,
      [ROLE_HR_MANAGER]: hrManagerMenuItems,
      [ROLE_DELIVERY_MANAGER]: deliveryManagerMenuItems,
    };
    return menuItemsMap[role] || [];
  };

  return (
    <SidebarContainer>
      {/* Logo and Sidebar Layout */}
      <LogoWrapper>
        <AppIcon>
          <span style={{ color: '#0666EB', fontWeight: 300 }}>N</span>
          <span style={{ color: '#2A2A2A', fontWeight: 600 }}>K</span>
        </AppIcon>
        <img src={LogoImage} alt='nimikash-logo' width='113' height='32' />
      </LogoWrapper>

      {user ? (
        <MenuContainer>
          {getMenuItemsByRole(user.adminRole).map((item, index) => (
            <MenuItem key={index} menuItem={item} />
          ))}
        </MenuContainer>
      ) : (
        <SkeletonContainer>
          <SkeletonTheme baseColor='#E6F0FD' highlightColor='#76AAF4'>
            <Skeleton count={1} height={50} />
            <br />
            <Skeleton count={1} height={50} />
            <br />
            <Skeleton count={1} height={50} />
            <br />
            <Skeleton count={1} height={50} />
          </SkeletonTheme>
        </SkeletonContainer>
      )}
    </SidebarContainer>
  );
};

SideNavbar.propTypes = {
  user: PropTypes.shape({
    adminRole: PropTypes.string.isRequired,
  }),
};

// Styled components
const SidebarContainer = styled(Box)({
  width: '280px',
  height: '100vh',
  background: colors.white,
  borderRight: '1px solid #F1F1F2',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const LogoWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '32px 24px',
  gap: '12px',
});

const MenuContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 16px',
  gap: '12px',
  height: '100vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#E9F0FD',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#c7c7c7',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
});

const SkeletonContainer = styled(Box)({
  padding: '24px',
});

const AppIcon = styled(Box)({
  width: '32px',
  height: '32px',
  background: '#F7F7F8',
  border: '0.4px solid #D3D3D6',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Readex Pro',
  fontSize: '19.6128px',
});

export default SideNavbar;
