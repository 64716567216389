/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import Table from '../tables/Step3-Table';
import SearchIcon from '@mui/icons-material/Search';
import { styleProps } from '../../../styles/styleProps';

const { fonts } = styleProps;

function Step3({ setBulkData, bulkData }) {
  const { colors } = styleProps;
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  // search by name or id
  useEffect(() => {
    const employees = Object.values(bulkData.employees ?? {});
    let filteredData = [];

    // convert employeees in bulkData to array in order to pass into table
    Object.values(employees).forEach((employee) => {
      filteredData.push(employee);
    });

    if (!searchValue || searchValue === '') {
      // if search value is empty, set filtered data to all employees
      setFilteredData(filteredData);
      return;
    }

    // filter employees by search value
    filteredData = filteredData.filter((row) => {
      return (
        row.employeeEmployeeId.toLowerCase().includes(searchValue) ||
        row.employeeFirstName.toLowerCase().includes(searchValue) ||
        row.employeeLastName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filteredData);
  }, [searchValue, bulkData]);

  const columns = [
    'Name',
    'EPF',
    'Advance',
    'Payee Tax',
    'Loan',
    'No Pay',
    'Total Pay',
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          marginY: 3,
        }}
      >
        <TextField
          size='small'
          placeholder='Search employee'
          sx={{ gap: '12px', ...SearchStyle, width: '220px' }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{ color: colors.textLightGrey, marginRight: '12px' }}
              />
            ),
          }}
        />
      </Box>
      <Table
        data={filteredData}
        columns={columns}
        bulkData={bulkData}
        setBulkData={setBulkData}
      />
    </>
  );
}

export default Step3;

const SearchStyle = {
  height: '48px',
  '& .MuiOutlinedInput-root': {
    height: '48px',
    borderRadius: '8px',
    border: '1px solid #BEBEC2',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    backgroundColor: '#FFFFFF',
    '& fieldset': {
      border: '1px solid #BEBEC2',
    },
    '&:hover fieldset': {
      border: '1px solid #BEBEC2',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #BEBEC2',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: fonts.primary,
    fontWeight: 400,
    fontSize: '16px',
    color: '#505057',
  },
};
