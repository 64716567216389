import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import {
  AccountBalanceWallet as WalletIcon,
  SupervisorAccount as UsersIcon,
  CalendarMonth as CalendarIcon,
  RequestQuote as FileIcon,
} from '@mui/icons-material';

import {
  getFBOBalance,
  getTotalEmployee,
  getLastPayDateAndAmount,
} from '../../../services/DasboardSummaryService';
import { styleProps } from '../../../styles/styleProps';

const { fonts } = styleProps;

const iconStyles = {
  color: '#0666EB',
  backgroundColor: '#E6F0FD',
  borderRadius: '50%',
  padding: '6px',
};

const metricsTemplate = [
  {
    icon: <WalletIcon sx={iconStyles} />,
    label: 'FBO Balance',
    fetchValue: async () => {
      const balance = await getFBOBalance();
      return `$ ${balance || 'N/A'}`;
    },
  },
  {
    icon: <UsersIcon sx={iconStyles} />,
    label: 'Total Employees',
    fetchValue: getTotalEmployee,
  },
  {
    icon: <CalendarIcon sx={iconStyles} />,
    label: 'Last Pay Date',
    fetchValue: async () => {
      const { fullDate } = await getLastPayDateAndAmount();
      return fullDate || 'N/A';
    },
  },
  {
    icon: <FileIcon sx={iconStyles} />,
    label: 'Last Payroll',
    fetchValue: async () => {
      const { lastPayRollAmount } = await getLastPayDateAndAmount();
      return `$ ${lastPayRollAmount || 'N/A'}`;
    },
  },
];

const FinancialOverview = () => {
  const [metrics, setMetrics] = useState(
    metricsTemplate.map((metric) => ({ ...metric, value: '' })),
  );

  const fetchData = async () => {
    const fetchedData = await Promise.all(
      metrics.map(async (metric) => ({
        ...metric,
        value: await metric.fetchValue(),
      })),
    );
    setMetrics(fetchedData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ ...styles.container }}>
      <Typography sx={styles.heading}>Financial Overview</Typography>

      <Box sx={styles.metricContainer}>
        {metrics.map((metric, index) => (
          <React.Fragment key={index}>
            <Box sx={{ ...styles.metricItem, display: 'flex' }}>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {metric.icon}
                <Typography sx={styles.label}>{metric.label}</Typography>
              </Box>
              <Typography sx={styles.value}>
                {metric.value || 'Loading...'}
              </Typography>
            </Box>
            {index !== metrics.length - 1 && <Divider sx={styles.divider} />}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default FinancialOverview;

const styles = {
  heading: {
    fontFamily: fonts.primary,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#2F2F33',
    width: '100%',
    gap: '8px',
    marginBottom: '24px',
  },
  metricContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
  metricItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: {
    fontFamily: fonts.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#3E3E43',
  },
  value: {
    fontFamily: fonts.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#71717A',
    textAlign: 'right',
  },
  divider: {
    width: '301px',
    height: 0,
    border: '1px solid #F1F1F2',
    margin: '10px 0',
  },
};
