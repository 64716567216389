import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Pay Period & Employee',
  'Additional Earnings',
  'Deductions',
  'Review',
];

// eslint-disable-next-line react/prop-types
const StepperComponent = ({ activeStep, children }) => {
  return (
    <Box sx={{ width: '100%', paddingTop: '3rem' }}>
      <Box sx={{ width: '80%', margin: '0 auto' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <Box sx={{ width: '100%', marginTop: '3rem' }}>{children}</Box>
    </Box>
  );
};

export default StepperComponent;
