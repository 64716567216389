import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useNavigate } from 'react-router-dom';

const OfferLetterTemplateTable = ({
  offerLetters,
  setSelectedRow,
  setOpenDrawer,
}) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table size='medium'>
        <TableHead>
          <TableRow sx={{ height: '40px', backgroundColor: '#F7F7F8' }}>
            <TableCell>Letter Type</TableCell>
            <TableCell>Hiring Entity</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offerLetters?.map((offerLetter) => (
            <TableRow key={offerLetter.id}>
              <TableCell>{offerLetter.letterType}</TableCell>
              <TableCell sx={{ fontSize: '13px', fontWeight: 400, color: '#667085' }}>{offerLetter.hiringEntity}</TableCell>
              <TableCell sx={{ fontSize: '13px', fontWeight: 400, color: '#667085' }}>{offerLetter.country}</TableCell>
              <TableCell sx={{ fontSize: '13px', fontWeight: 400, color: '#667085' }}>{offerLetter.currency}</TableCell>
              <TableCell
                width={30}
                onClick={() => {
                  navigate(`/offerLetter/template/${offerLetter.displayId}`, {
                    state: { letterType: offerLetter.letterType },
                  });
                }}
                sx={{ color: '#667085' }}
              >
                <RemoveRedEyeOutlinedIcon sx={{ fontSize: 20 }} />
              </TableCell>
              <TableCell
                width={30}
                onClick={() => {
                  setOpenDrawer(true);
                  setSelectedRow(offerLetter);
                }}
                sx={{ color: '#667085' }}
              >
                <SendOutlinedIcon sx={{ fontSize: 20 }}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OfferLetterTemplateTable;

OfferLetterTemplateTable.propTypes = {
  offerLetters: PropTypes.array,
  setSelectedRow: PropTypes.func,
  setOpenDrawer: PropTypes.func,
};
