import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Selector from '../selector';
import FileUpload from '../../inputs/fileUpload';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { styleProps } from '../../../styles/styleProps';
import axios from 'axios';

const { fonts } = styleProps;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const OfferLetterUploadModal = ({ open, setOpen }) => {
  const filesAccepted = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const formSchema = Yup.object().shape({
    letterType: Yup.string().required('Required'),
    hiringEntity: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    currency: Yup.string().required('Required'),
    file: Yup.mixed().required('Required'),
  });

  const initialState = {
    letterType: '',
    hiringEntity: '',
    country: '',
    currency: '',
    file: null,
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('file', values.file[0]);
      formData.append(
        'formData',
        JSON.stringify({
          letterType: values.letterType,
          hiringEntity: values.hiringEntity,
          country: values.country,
          currency: values.currency,
        }),
      );
      try {
        const token = localStorage.getItem('loggedInAuth');

        if (!token) {
          throw new Error('User is not authenticated');
        }

        const response = await axios.post(
          '/api/offer-letter-templates',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `"${token}"`,
            },
          },
        );

        if (response.data.success) {
          handleClose();
        } else {
          console.error('Error from server:', response.data.error);
        }
      } catch (err) {
        console.error('Upload failed:', err.message);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const letterTypes = ['Intern', 'Contract', 'Permanent'];
  const hiringEntities = ['Nimi Tech Limited', 'Nimi LLC'];
  const countries = ['Sri Lanka', 'United States', 'India'];
  const currencies = ['LKR', 'USD', 'INR'];

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      maxWidth='lg'
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
        },
      }}
    >
      <DialogTitle
        id='customized-dialog-title'
        onClose={handleClose}
        sx={{ fontFamily: fonts.primary }}
      >
        Offer Letter Template
      </DialogTitle>
      <DialogContent dividers>
        <Typography sx={{ fontFamily: fonts.primary }}>
          Choose the offer letter properties
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            direction='row'
            spacing={2}
            sx={{ marginTop: '24px', marginBottom: '40px' }}
          >
            <Selector
              name='letterType'
              label='Letter type'
              options={letterTypes}
              value={formik.values.letterType}
              onChange={formik.handleChange}
            />
            <Selector
              name='hiringEntity'
              label='Hiring entity'
              options={hiringEntities}
              value={formik.values.hiringEntity}
              onChange={formik.handleChange}
            />
            <Selector
              name='country'
              label='Country'
              options={countries}
              value={formik.values.country}
              onChange={formik.handleChange}
            />
            <Selector
              name='currency'
              label='Currency'
              options={currencies}
              value={formik.values.currency}
              onChange={formik.handleChange}
            />
          </Stack>
          <Typography marginBottom='20px' sx={{ fontFamily: fonts.primary }}>
            Choose the template file
          </Typography>
          <Stack>
            <FileUpload
              files={formik.values.file}
              setFiles={(files) => formik.setFieldValue('file', files)}
              onError={(error) => console.log(error)}
              filesAccepted={filesAccepted}
              sx={{ fontFamily: fonts.primary }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}
      >
        <NewButton
          sx={{ color: '#2F2F33', fontFamily: fonts.primary }}
          onClick={handleClose}
        >
          Cancel
        </NewButton>
        <NewButton
          type='submit'
          variant='contained'
          color='primary'
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || !formik.dirty}
          sx={{ fontFamily: fonts.primary }}
        >
          Upload
        </NewButton>
      </DialogActions>
    </BootstrapDialog>
  );
};

OfferLetterUploadModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default OfferLetterUploadModal;

const NewButton = styled(Button)(() => ({
  width: '100%',
  height: '48px',
  borderRadius: '12px',
  textTransform: 'none',
  border: '1px solid #E2E8F0',
  fontFamily: fonts.primary,
}));
