import React, { useEffect, useState } from 'react';
import {
  Modal,
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
} from '@mui/material';
import {
  addSignature,
  getSignatureDetails,
} from '../../services/SignatureService';
import SecondaryButton from '../buttons/SecondaryButton';
import DrawSignatureTab from '../signature/DrawSignatureTab';
import TypeSignatureTab from '../signature/TypeSignatureTab';
import UploadSignatureTab from '../signature/UploadSignatureTab';
import PrimaryButton from '../buttons/PrimaryButton';
import { styleProps } from '../../styles/styleProps';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import dataUrlToFileConverter from '../signature/helper/dataUrlToFileConverter';
import PropTypes from 'prop-types';

const SignatureUpload = ({ userEmail, onComplete }) => {
  const [open, setOpen] = useState(false);
  const [currentTab, setcurrentTab] = useState(0);
  const [drawTabSignature, setDrawTabSignature] = useState(null);
  const [typeTabSignature, setTypeTabSignature] = useState(null);
  const [uploadTabSignature, setUploadTabSignature] = useState(null);
  const [existingSignature, setExistingSignature] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchExistingSignature = async () => {
      const signature = await getSignatureDetails();
      setExistingSignature(signature);
      setIsLoading(false);
    };
    fetchExistingSignature();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onTabChange = (event, newValue) => {
    setcurrentTab(newValue);
    setDrawTabSignature(null);
    setTypeTabSignature(null);
    setUploadTabSignature(null);
  };

  const onCaptureSignature = (signature, tabIndex) => {
    switch (tabIndex) {
      case 0:
        setDrawTabSignature(signature);
        break;
      case 1:
        setTypeTabSignature(signature);
        break;
      case 2:
        setUploadTabSignature(signature);
        break;
      default:
        break;
    }
  };

  const handleAdd = async () => {
    if (drawTabSignature || typeTabSignature || uploadTabSignature) {
      const formData = new FormData();

      if (drawTabSignature) {
        const file = dataUrlToFileConverter(drawTabSignature, 'signature.png');
        formData.append('image', file);
      } else if (typeTabSignature) {
        const file = dataUrlToFileConverter(typeTabSignature, 'signature.png');
        formData.append('image', file);
      } else if (uploadTabSignature instanceof File) {
        formData.append('image', uploadTabSignature);
      }
      formData.append('userEmail', userEmail);

      await addSignature(formData);
      onClose();
      if (onComplete) onComplete();
    }
  };

  const isAddButtonDisabled =
    !drawTabSignature && !typeTabSignature && !uploadTabSignature;

  return (
    <>
      <Button
        onClick={handleOpen}
        disabled={isLoading}
        sx={{
          textTransform: 'none',
          fontSize: 16,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <AddCircleRoundedIcon sx={{ marginRight: 0.5 }} />
        {existingSignature ? 'Update Signature' : 'Add Signature'}
      </Button>

      <Modal open={open} onClose={onClose}>
        <Paper sx={signaturePaperStyle}>
          <Typography variant='h5' align='start' sx={{ padding: 2 }}>
            Signature
          </Typography>
          <Box sx={tabContainerStyles}>
            <Tabs
              value={currentTab}
              onChange={(event, newValue) => {
                onTabChange(event, newValue);
                onCaptureSignature(null, newValue);
              }}
              start
              sx={{ marginLeft: 2 }}
            >
              <Tab sx={tabStyles} label='Draw' />
              <Tab sx={tabStyles} label='Type' />
              <Tab sx={tabStyles} label='Upload' />
            </Tabs>
            <Box>
              <TabPanel value={currentTab} index={0}>
                <DrawSignatureTab
                  onCapture={(signature) => onCaptureSignature(signature, 0)}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={1}>
                <TypeSignatureTab
                  onCapture={(signature) => onCaptureSignature(signature, 1)}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={2}>
                <UploadSignatureTab
                  onCapture={(signature) => onCaptureSignature(signature, 2)}
                />
              </TabPanel>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 2,
              gap: 5,
            }}
          >
            <SecondaryButton
              onClick={onClose}
              styles={addCancelBtnStyle}
              color={'gray'}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={handleAdd}
              styles={addCancelBtnStyle}
              disabled={isAddButtonDisabled}
            >
              Add
            </PrimaryButton>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      sx={{ width: '100%' }}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

SignatureUpload.propTypes = {
  userEmail: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
};

export default SignatureUpload;

const addCancelBtnStyle = {
  width: '150px',
  height: '50px',
  borderRadius: '30px',
  fontWeight: 600,
  fontSize: '16px',
  fontFamily: styleProps.fonts.primary,
  textTransform: 'none',
  padding: '10px 18px 10px 18px',
  border: '1px solid #979797',
};
const signaturePaperStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  height: 403,
};
const tabContainerStyles = {
  backgroundColor: '#f0f0f0',
  width: 512,
  height: 220,
  margin: 3,
  borderRadius: 1,
};

const tabStyles = {
  textTransform: 'none',
  fontSize: 16,
};
