import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TopBar from '../components/top-bar/TopBar';
import { getCurrentAdmin } from '../services/AdminService';
import PayrollUpdatesLog from '../components/dashboard/PayrollUpdatesLog/PayrollUpdatesLog';
import FinancialOverview from '../components/dashboard/FinancialOverview/FinancialOverview';
import OnLeaveToday from '../components/dashboard/OnLeaveToday/OnLeaveToday.js';
import SalaryAdvanceRequests from '../components/dashboard/SalaryAdvanceRequests/SalaryAdvanceRequests.js';
import UpcomingCelebrations from '../components/dashboard/UpcomingCelebrations/UpcomingCelebrations.js';
import OnboardingReminders from '../components/dashboard/OnboardingReminders/OnboardingReminders.js';

const Dashboard = () => {
  const [user, setUser] = useState('');

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setUser(await getCurrentAdmin());
  }

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TopBar title='Dashboard' user={user} />
        <DashboardContainer>
          {/* First row */}
          <DashboardRow>
            <StandardCard>
              <OnLeaveToday />
            </StandardCard>
            <StandardCard>
              <SalaryAdvanceRequests />
            </StandardCard>
            <StandardCard>
              <OnboardingReminders />
            </StandardCard>
          </DashboardRow>

          {/* Second row */}
          <DashboardRow>
            <TallCard>
              <PayrollUpdatesLog />
            </TallCard>
            <TallCard>
              <FinancialOverview />
            </TallCard>
            <TallCard>
              <UpcomingCelebrations />
            </TallCard>
          </DashboardRow>
        </DashboardContainer>
      </Box>
    </>
  );
};

export default Dashboard;

// Styled components for dashboard cards
const DashboardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '24px',
  width: '100%',
  minHeight: '379px',
  flexGrow: 1,
  flexShrink: 0,
  flexWrap: 'wrap',

  // Add responsiveness
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '0 16px',
    minHeight: 'auto',
  },
}));

const DashboardCard = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  maxWidth: '349px',
  background: '#FFFFFF',
  border: '1px solid #F1F1F2',
  boxShadow:
    '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  borderRadius: '16px',
  flex: '1 1 auto',

  // Add responsiveness
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '8px 0',
  },
}));

const StandardCard = styled(DashboardCard)({
  minHeight: '379px',
});

const TallCard = styled(DashboardCard)({
  minHeight: '477px',
});

const DashboardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxHeight: 'calc(100vh - 80px)', // Subtract TopBar height
  overflowY: 'auto',
  padding: '24px 0',

  // Add responsiveness
  [theme.breakpoints.down('md')]: {
    padding: '16px 0',
    gap: '16px',
  },
}));
