import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import SideNavbar from '../components/side-navbar/SideNavbar';
import { useAuth } from '../contexts/authContext';

const Layout = ({ children }) => {
  const { user } = useAuth();

  return (
    <>
      <Box sx={{ flexGrow: 1 }} display='flex' flexDirection='row'>
        <Box flex width={'280px'}>
          <SideNavbar user={user} />
        </Box>
        <Box flex={10}>
          {/* TODO: Add TopBar */}
          {/* <TopBar title={selectedPage.title} user={user} /> */}
          {children}
        </Box>
      </Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
