import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { styleProps } from '../../../styles/styleProps';
import { resolvePath } from '../../../data/routeDefinition';

const { fonts, colors } = styleProps;

const Menu = ({
  data,
  disableBackground = false,
  setParentState = () => {},
}) => {
  const location = useLocation();
  const isSelected = resolvePath(data.to) === location.pathname;

  useEffect(() => {
    setParentState(isSelected);
  }, [isSelected, setParentState]);

  const ItemContainer = disableBackground
    ? BaseMenuLink
    : styled(BaseMenuLink)(({ active }) => ({
        background: active ? '#F2F7FD' : 'transparent',
      }));

  return (
    <ItemContainer to={data.to} active={isSelected ? 'true' : undefined}>
      <ItemWrapper active={isSelected ? 'true' : undefined}>
        {data.icon && React.createElement(data.icon, {})}
        <ItemText active={isSelected ? 'true' : undefined}>
          {data.title}
        </ItemText>
      </ItemWrapper>
    </ItemContainer>
  );
};

Menu.propTypes = {
  data: PropTypes.shape({
    to: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    title: PropTypes.string.isRequired,
  }).isRequired,
  disableBackground: PropTypes.bool,
  setParentState: PropTypes.func,
};

export default Menu;

const BaseMenuLink = styled(Link)(({ active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '248px',
  maxHeight: '40px',
  cursor: 'pointer',
  padding: '8px 12px',
  borderRadius: '6px',
  textDecoration: 'none',
  '& svg': {
    color: active === 'true' ? '#0666EB' : '#71717A',
  },
  '& span': {
    color: active === 'true' ? '#0448A7' : '#505057',
  },
  '&:hover': {
    background: active === 'true' ? '#DEEBFC' : '#F2F7FD',
  },
}));

const ItemWrapper = styled(Box)(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  color: active === 'true' ? colors.textPrimary : 'inherit',
}));

const ItemText = styled(Typography)(({ active }) => ({
  fontWeight: 500,
  fontSize: '16px',
  fontFamily: fonts.primary,
  color: active === 'true' ? '#0666EB' : '#505057',
}));
