/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import { Typography, styled } from '@mui/material';
import { styleProps } from '../../../styles/styleProps';

const { fonts } = styleProps;

function ExpensesChart({ calculatedData }) {
  // get percentage of each field
  const percentageGetter = (fieldId) => {
    const fieldValue = calculatedData[fieldId];
    const total =
      calculatedData.salary +
      calculatedData.fuelAndMaintenance +
      calculatedData.travel +
      calculatedData.dailyPay +
      calculatedData.overtime +
      calculatedData.bonus +
      calculatedData.reimbursements;
    // Avoid division by zero
    if (!total) return 0;

    return ((fieldValue / total) * 100).toFixed(2);
  };

  // default data for chart from props
  const dataList = [
    {
      label: `Salary - ${percentageGetter('salary')}%`,
      data: calculatedData.totalPay,
    },
    {
      label: `Fuel & Maintenance - ${percentageGetter('fuelAndMaintenance')}%`,
      data: calculatedData.fuelAndMaintenance,
    },
    {
      label: `Travel - ${percentageGetter('travel')}%`,
      data: calculatedData.travel,
    },
    {
      label: `Daily Pay - ${percentageGetter('dailyPay')}%`,
      data: calculatedData.dailyPay,
    },
    {
      label: `Overtime - ${percentageGetter('overtime')}%`,
      data: calculatedData.overtime,
    },
    {
      label: `Bonus - ${percentageGetter('bonus')}%`,
      data: calculatedData.bonus,
    },
    {
      label: `Reimbursements - ${percentageGetter('reimbursements')}%`,
      data: calculatedData.reimbursements,
    },
  ];

  // filter out data with 0 values
  const labels = dataList
    .filter((item) => item.data > 0)
    .map((item) => item.label);

  const dataValues = dataList
    .filter((item) => item.data > 0)
    .map((item) => item.data);

  const backgroundColor = [
    '#59C3CF',
    '#E2635E',
    '#A66CFD',
    '#F1CD49',
    '#4887F6',
    '#FF9A3C',
    '#CF92DE',
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Amount',
        data: dataValues,
        backgroundColor: backgroundColor,
        hoverOffset: 10,
      },
    ],
  };

  const config = {
    type: 'doughnut',
    data: data,
    options: {
      animations: {
        radius: {
          duration: 500,
          easing: 'linear',
          loop: (context) => context.active,
        },
      },
      borderWidth: 0,
      responsive: true,
      cutout: '80%',
      plugins: {
        legend: {
          position: 'right',
          labels: {
            boxWidth: 20,
            boxHeight: 20,
            padding: 30,
            pointStyle: 'circle',
            usePointStyle: true,
            font: {
              size: 14,
            },
          },
        },
      },
    },
  };

  useEffect(() => {
    const ctx = document.getElementById('expenses-chart').getContext('2d');
    const myChart = new Chart(ctx, config);

    return () => {
      myChart.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedData]);

  return (
    <div className='chart-container' style={styles.chartContainer}>
      <CustomTypography variant='h5' fontWeight={400}>
        Payroll Overview
      </CustomTypography>
      <canvas style={styles.canvas} id='expenses-chart'></canvas>
    </div>
  );
}

export default ExpensesChart;

const styles = {
  chartContainer: {
    position: 'relative',
    padding: '96px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  canvas: {
    maxHeight: '400px',
    maxWidth: '960px',
  },
};

const CustomTypography = styled(Typography)({
  fontFamily: fonts.primary,
  position: 'absolute',
  top: 10,
  left: 10,
});
