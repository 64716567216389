/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Modal, Button, Stack, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { styleProps } from '../../styles/styleProps';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

const { colors } = styleProps;

const buttonBorderStyle = (type) => ({
  border: `2px solid ${type === 'Enable' ? '#00909E' : '#EA5455'}`,
  width: 24,
  height: 24,
  borderRadius: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});

const StatusChangeConfirmationModal = ({
  modalStatus,
  confirm,
  reject,
  type,
  text,
  title,
}) => {
  const color = type === 'Enable' ? 'enabled' : 'disabled';
  const colorCode = type === 'Enable' ? '#00909E' : '#EA5455'; // we have to use color code for non mui components
  return (
    <>
      <div>
        <Modal open={modalStatus}>
          <Box sx={style}>
            <Stack justifyContent='space-between' direction='row'>
              <Title sx={{ color: colorCode }}>{title}</Title>

              <Button onClick={reject}>
                <Box
                  style={{
                    ...buttonBorderStyle(type),
                    borderColor: color,
                  }}
                >
                  <Box
                    style={{
                      ...styles.closeBtn,
                      color: color,
                    }}
                  >
                    <CloseIcon fontSize='16px' color={color} />
                  </Box>
                </Box>
              </Button>
            </Stack>

            <Stack justifyContent='space-between' direction='row'>
              <ModalBody>{text}</ModalBody>
            </Stack>

            <Stack spacing={1} direction='row' sx={stackStyle}>
              <PrimaryButton
                color={color}
                styles={styles.buttonStyle}
                onClick={confirm}
              >
                YES
              </PrimaryButton>
              <SecondaryButton
                color={color}
                styles={styles.buttonStyle}
                onClick={reject}
              >
                NO
              </SecondaryButton>
            </Stack>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default StatusChangeConfirmationModal;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '220px',
  background: '#FFFFFF',
  borderRadius: '8px',
  width: 550,
};

const ModalBody = styled(Typography)({
  marginTop: '2%',
  color: colors.textDarkGrey,
  marginLeft: '3%',
  fontWeight: '400',
  fontSize: '16px',
});

const stackStyle = {
  marginTop: 5,
  alignItems: 'right',
  justifyContent: 'right',
  marginRight: '4%',
};

const Title = styled(Typography)({
  fontWeight: '500',
  fontSize: '16px',
  padding: '3%',
});

const styles = {
  buttonStyle: {
    fontSize: '17px',
    width: '110px',
  },
  closeBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};
