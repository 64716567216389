import React from 'react';
import { Box, Typography } from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import PropTypes from 'prop-types';
import { styleProps } from '../../../styles/styleProps';

const { fonts } = styleProps;

const OnLeaveToday = ({ cardStyle }) => {
  return (
    <Box sx={{ ...styles.container, ...cardStyle }}>
      {/* Header */}
      <Box sx={styles.header}>
        <Typography sx={{ ...styles.heading }}>On Leave Today</Typography>
      </Box>

      {/* Empty state content */}
      <Box sx={styles.emptyStateContainer}>
        <Box sx={styles.emptyMsgContainer}>
          <Box sx={styles.iconContainer}>
            <CalendarIcon sx={styles.calenderIcon} />
          </Box>
          <Box>
            <Typography sx={styles.emptyStateText}>
              No employees are taking leave today
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

OnLeaveToday.propTypes = {
  cardStyle: PropTypes.object,
};

export default OnLeaveToday;

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    width: '301px',
    height: '22px',
    justifyContent: 'space-between',
  },
  heading: {
    fontFamily: fonts.primary,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#2F2F33',
    flexGrow: 1,
    minWidth: '185px',
    gap: '8px',
  },
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '288px',
    gap: '16px',
  },
  emptyMsgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
  iconContainer: {
    width: '48px',
    height: '48px',
  },
  calenderIcon: {
    width: '100%',
    height: '100%',
    color: '#BEBEC2',
  },
  emptyStateText: {
    fontFamily: fonts.primary,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#A0A0A6',
  },
};
