import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styleProps } from '../../styles/styleProps';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const { fonts } = styleProps;

const Selector = ({ name, label, options, value, onChange }) => (
  <FormControl fullWidth sx={{ width: 200 }}>
    <InputLabel sx={{ fontFamily: fonts.primary }}>{label}</InputLabel>
    <Select
      label={label}
      sx={{
        borderRadius: '8px',
        fontFamily: fonts.primary,
      }}
      value={value}
      onChange={onChange}
      name={name}
      IconComponent={ExpandMoreIcon}
    >
      {options?.map((option, index) => (
        <MenuItem key={index} value={option} sx={{ fontFamily: fonts.primary }}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

Selector.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default Selector;
