/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import Table from '../tables/Step2-Table';
import SearchIcon from '@mui/icons-material/Search';
import { styleProps } from '../../../styles/styleProps';
import { toMonthName } from '../../../utils/TemporalService';
import PayRollFormatter from '../PayRollFormatter';
import { getCurrentAdmin } from '../../../services/AdminService';
import { getAllPayStubsOfCompanyByMonthYear } from '../../../services/PaysubServices';
import { getEmployeeByStatus } from '../../../services/EmployeeService';
import { EmployeeStatus } from '../../../enums/Employee';

const { fonts } = styleProps;

function Step2({ selectedEmployees, setBulkData, bulkData }) {
  const { colors } = styleProps;
  const [loadingPayStubs, setLoadingPayStubs] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [payStubs, setPayStubs] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [displayId, setdDisplayId] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);

  // assign paystubs to bulkData state when selected employees change
  useEffect(() => {
    if (loadingPayStubs) return;

    const employees = bulkData.employees ?? {};

    // only keep selected paystubs in previous step
    const filteredPaystubs = payStubs.filter((paystub) => {
      return selectedEmployees.has(paystub.employeeDisplayId);
    });

    // Remove employees that are not selected
    Object.keys(employees).forEach((employeeDisplayId) => {
      if (!selectedEmployees.has(employeeDisplayId)) {
        delete employees[employeeDisplayId];
      }
    });

    for (let paystub of filteredPaystubs) {
      const matchingEmployee = employeeList.find(
        (employee) => employee.employeeDisplayId === paystub.employeeDisplayId,
      );

      const employeeDisplayId = paystub.employeeDisplayId;
      const employeeType = matchingEmployee?.employeeType;

      // If it's a consultant, replace their record with the one from payStubs
      if (employeeType === 'Consultant') {
        employees[employeeDisplayId] = new PayRollFormatter(
          paystub,
          employeeType,
        );
      }
      // If not a consultant, add to employees if not already there
      else if (!employees[employeeDisplayId]) {
        employees[employeeDisplayId] = new PayRollFormatter(
          paystub,
          employeeType,
        );
      }
    }

    setBulkData((prev) => ({
      ...prev,
      employees: Object.assign({}, employees),
    }));

    // eslint-disable-next-line
  }, [selectedEmployees, payStubs]);

  // search by name or id
  useEffect(() => {
    const employees = Object.values(bulkData.employees ?? {});
    let filteredData = [];

    // convert employeees in bulkData to array in order to pass into table
    Object.values(employees).forEach((employee) => {
      filteredData.push(employee);
    });

    if (!searchValue || searchValue === '') {
      // if search value is empty, set filtered data to all employees
      setFilteredData(filteredData);
      return;
    }

    // filter employees by search value
    filteredData = filteredData.filter((row) => {
      return (
        row.employeeEmployeeId.toLowerCase().includes(searchValue) ||
        row.employeeFirstName.toLowerCase().includes(searchValue) ||
        row.employeeLastName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filteredData);
  }, [searchValue, bulkData]);

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const adminData = await getCurrentAdmin();

        const companyDisplayId = adminData.adminCompany.companyDisplayId;

        setdDisplayId(companyDisplayId);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAdmin();
  }, []);

  const columns = [
    'Name',
    'Fuel & Maintenance',
    'Traveling',
    'Daily Paid',
    'Over time',
    'Bonus',
    'Reimbursement',
    'Total Pay',
  ];

  useEffect(() => {
    if (displayId) {
      getAllEmployees();
    }

    // eslint-disable-next-line
  }, [displayId]);

  const getAllEmployees = async () => {
    setLoadingPayStubs(true);

    const year = new Date(bulkData.payDate).getFullYear();
    const month = toMonthName(new Date(bulkData.payDate).getMonth() + 1); // +1 because month starts from 0
    let name = '';
    const data = await getAllPayStubsOfCompanyByMonthYear(
      displayId,
      year,
      month,
      name,
    );
    const res = await getEmployeeByStatus(EmployeeStatus.ENABLED);
    setEmployeeList(res.employees);

    setPayStubs(data.message ?? []);
    setLoadingPayStubs(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          marginY: 3,
        }}
      >
        <TextField
          size='small'
          placeholder='Search employee'
          sx={{ gap: '12px', ...SearchStyle, width: '220px' }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{ color: colors.textLightGrey, marginRight: '12px' }}
              />
            ),
          }}
        />
      </Box>
      <Table
        data={filteredData}
        columns={columns}
        bulkData={bulkData}
        setBulkData={setBulkData}
      />
    </>
  );
}

export default Step2;

const SearchStyle = {
  height: '48px',
  '& .MuiOutlinedInput-root': {
    height: '48px',
    borderRadius: '8px',
    border: '1px solid #BEBEC2',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    backgroundColor: '#FFFFFF',
    '& fieldset': {
      border: '1px solid #BEBEC2',
    },
    '&:hover fieldset': {
      border: '1px solid #BEBEC2',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #BEBEC2',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: fonts.primary,
    fontWeight: 400,
    fontSize: '16px',
    color: '#505057',
  },
};
