import React from 'react';

import RegistrationForm from '../components/registration-flow/registration-flow-invite/registrationForm';
import GenericSection from '../components/generic/GenericSection';

const GenericRegistration = () => {
  return (
    <>
      <GenericSection>
        <RegistrationForm />
      </GenericSection>
    </>
  );
};

export default GenericRegistration;
