import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const WeeklySummaryPanel = ({ employee, onClose, weekDays }) => {
  const [totalWeeklyHours, setTotalWeeklyHours] = useState(0);
  const [editableTimeRecords, setEditableTimeRecords] = useState([]);
  const [changedRecords, setChangedRecords] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    // Initialize editable records for each weekday
    const updatedRecords = weekDays.map((weekDay) => {
      const existingRecord = employee.timeRecords.find(
        (rec) => rec.startTs.split('T')[0] === weekDay.date,
      );

      return (
        existingRecord || {
          recordId: null,
          startTs: `${weekDay.date}T08:00:00.000Z`,
          endTs: `${weekDay.date}T08:01:00.000Z`,
        }
      );
    });

    setEditableTimeRecords(updatedRecords);
  }, [employee.timeRecords, weekDays]);

  const validateTimeFormat = (time) => {
    return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
  };

  const handleTimeChange = (date, field, value) => {
    if (!validateTimeFormat(value)) {
      setError('Invalid time format. Please use HH:MM format.');
      return;
    }

    setError(null);
    setHasUnsavedChanges(true);

    setEditableTimeRecords((prev) => {
      const updatedRecords = prev.map((record) => {
        if (record.startTs.split('T')[0] === date) {
          const datePart = record[field]?.split('T')[0] || date;
          const updatedRecord = {
            ...record,
            [field]: `${datePart}T${value}:00.000Z`,
          };

          setChangedRecords((prevChanges) => ({
            ...prevChanges,
            [date]: updatedRecord,
          }));

          return updatedRecord;
        }
        return record;
      });

      calculateTotalWeeklyHours(updatedRecords);
      return updatedRecords;
    });
  };

  const calculateTotalWeeklyHours = (records) => {
    const currentWeekDates = weekDays.map((day) => day.date);
    let totalHours = 0;

    records.forEach((record) => {
      try {
        const date = record.startTs.split('T')[0];

        // Skip if the date is not in current week
        if (!currentWeekDates.includes(date)) {
          return;
        }

        // Only consider records that either exist in employee.timeRecords or have been edited
        const isExistingRecord = employee.timeRecords.some(
          (rec) => rec.startTs.split('T')[0] === date,
        );
        const isEdited = changedRecords[date] !== undefined;

        if (!isExistingRecord && !isEdited) {
          return;
        }

        const checkInTime = new Date(record.startTs);
        const checkOutTime = new Date(record.endTs);

        if (isNaN(checkInTime.getTime()) || isNaN(checkOutTime.getTime())) {
          console.warn('Invalid date found in record:', record);
          return;
        }

        // Calculate hours worked
        const hoursWorked = Math.max(
          0,
          (checkOutTime - checkInTime) / (1000 * 60 * 60),
        );

        // Round to 2 decimal places for each day before adding to total
        totalHours += Math.round(hoursWorked * 100) / 100;
      } catch (error) {
        console.error('Error calculating hours for record:', record, error);
      }
    });

    // Round the final total to 2 decimal places
    return totalHours.toFixed(2);
  };

  useEffect(() => {
    const totalHours = calculateTotalWeeklyHours(editableTimeRecords);
    setTotalWeeklyHours(totalHours);
  }, [editableTimeRecords, weekDays]);

  const saveChanges = async () => {
    const token = localStorage.getItem('loggedInAuth');
    setIsLoading(true);
    setError(null);

    const recordsToSend = Object.values(changedRecords).map((record) => ({
      dateTime1: record.startTs.replace('T', ' ').replace('.000Z', ''),
      dateTime2: record.endTs.replace('T', ' ').replace('.000Z', ''),
      employeeDisplayId: employee.employeeDisplayId,
    }));

    if (recordsToSend.length > 0) {
      try {
        const response = await axios.post(
          '/api/time-records?action=check-in-manual',
          JSON.stringify(recordsToSend),
          {
            headers: {
              Authorization: `"${token}"`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (!response.status) {
          throw new Error('Failed to save time records');
        }

        setSaveSuccess(true);
        setHasUnsavedChanges(false);
        setTimeout(() => setSaveSuccess(false), 3000);
      } catch (error) {
        setError(`Error saving time records: ${error.message}`);
        console.error('Error saving time records:', error);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '400px',
          height: '100%',
          backgroundColor: 'white',
          boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Fixed Header Section */}
        <Box
          sx={{
            padding: '20px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mb={2}
          >
            <Typography variant='h5'>Weekly Summary</Typography>
            <IconButton
              onClick={() => {
                if (hasUnsavedChanges) {
                  setShowConfirmDialog(true); // Show confirmation dialog
                } else {
                  onClose(); // No unsaved changes, close immediately
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant='h6' sx={{ marginBottom: '20px' }}>
            {employee.name}
          </Typography>

          {error && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {saveSuccess && (
            <Alert severity='success' sx={{ mb: 2 }}>
              Time records saved successfully!
            </Alert>
          )}
        </Box>

        {/* Scrollable Records Section */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            padding: '20px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          {weekDays.map((weekDay) => {
            const record = editableTimeRecords.find(
              (rec) => rec.startTs?.split('T')[0] === weekDay.date,
            ) || {
              startTs: `${weekDay.date}T08:00:00.000Z`,
              endTs: `${weekDay.date}T17:00:00.000Z`,
            };

            return (
              <Box key={weekDay.date} mb={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <Typography variant='body2'>
                    {weekDay.day}, {new Date(weekDay.date).toLocaleDateString()}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label='Check-in'
                    type='time'
                    value={record.startTs.split('T')[1].slice(0, 5)}
                    onChange={(e) =>
                      handleTimeChange(weekDay.date, 'startTs', e.target.value)
                    }
                    fullWidth
                    size='small'
                    inputProps={{
                      step: 300,
                    }}
                  />
                  <TextField
                    label='Check-out'
                    type='time'
                    value={record.endTs.split('T')[1].slice(0, 5)}
                    onChange={(e) =>
                      handleTimeChange(weekDay.date, 'endTs', e.target.value)
                    }
                    fullWidth
                    size='small'
                    inputProps={{
                      step: 300,
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>

        {/* Fixed Footer Section */}
        <Box
          sx={{
            padding: '20px',
            backgroundColor: 'white',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Typography variant='h6' mb={3}>
            Total Weekly Hours: {totalWeeklyHours} hrs
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={saveChanges}
              disabled={isLoading || !hasUnsavedChanges}
              startIcon={isLoading && <CircularProgress size={20} />}
            >
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Box>
      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '8px',
            padding: '8px',
          },
        }}
      >
        <DialogTitle
          sx={{
            pb: 1,
            fontSize: '1.25rem',
            fontWeight: 600,
          }}
        >
          Unsaved Changes
        </DialogTitle>
        <DialogContent sx={{ pb: 3 }}>
          <Typography sx={{ color: 'text.secondary' }}>
            You have unsaved changes. Are you sure you want to close without
            saving?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            pb: 2,
            gap: 1,
          }}
        >
          <Button
            onClick={() => setShowConfirmDialog(false)}
            variant='outlined'
            size='medium'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowConfirmDialog(false);
              onClose();
            }}
            color='error'
            variant='outlined'
            size='medium'
          >
            Close Without Saving
          </Button>
          <Button
            onClick={() => {
              saveChanges();
              onClose();
            }}
            color='primary'
            variant='contained'
            size='medium'
          >
            Save and Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

WeeklySummaryPanel.propTypes = {
  employee: PropTypes.shape({
    name: PropTypes.string.isRequired,
    employeeDisplayId: PropTypes.string.isRequired,
    timeRecords: PropTypes.arrayOf(
      PropTypes.shape({
        recordId: PropTypes.string,
        startTs: PropTypes.string.isRequired,
        endTs: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  weekDays: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      day: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default WeeklySummaryPanel;
