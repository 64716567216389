import axios from 'axios';

const getAuthToken = () => localStorage.getItem('loggedInAuth');

const HolidayService = {
  createHoliday: async (holidayData) => {
    try {
      const token = getAuthToken();
      const response = await axios.post('/api/holidays', holidayData, {
        headers: { Authorization: `"${token}"` },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating holiday:', error);
      throw error;
    }
  },

  deleteHoliday: async (holidayData) => {
    try {
      const token = getAuthToken();
      const response = await axios.delete('/api/holidays', {
        headers: { Authorization: `"${token}"` },
        data: holidayData,
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting holiday:', error);
      throw error;
    }
  },

  updateHoliday: async (oldHolidayData, newHolidayData) => {
    try {
      const token = getAuthToken();
      const response = await axios.put(
        '/api/holidays',
        {
          holidayName: oldHolidayData.holidayName,
          holidayDate: oldHolidayData.holidayDate,
          newHolidayName: newHolidayData.newHolidayName,
          newHolidayDate: newHolidayData.newHolidayDate,
        },
        {
          headers: { Authorization: `"${token}"` },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error updating holiday:', error);
      throw error;
    }
  },

  getHolidays: async (startDate) => {
    try {
      const token = getAuthToken();
      const response = await axios.get(`/api/holidays?startDate=${startDate}`, {
        headers: { Authorization: `"${token}"` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching holidays:', error);
      throw error;
    }
  },
};

export default HolidayService;
