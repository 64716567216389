import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  LinearProgress,
  Button,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styleProps } from '../../styles/styleProps';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as DocxIcon } from '../../assets/svgs/Docx.svg';

const { fonts } = styleProps;

const FileUpload = ({ filesAccepted, onError, files, setFiles }) => {
  const inputRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = useState(null); // TODO: can use progress if plan to upload heavy template files and implementing a request dedicated for the upload

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  //handle file drop event
  const handleDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (!filesAccepted.includes(files[0].type)) {
      onError('File type not supported');
      return;
    }
    setFiles(files);
  };

  //handle file browse event
  const uploadFiles = (event) => {
    const files = event.target.files;
    if (!filesAccepted.includes(files[0].type)) {
      onError('File type not supported');
      return;
    }
    setFiles(files);
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '12px',
        alignItems: 'center',
        margin: '0 auto',
      }}
    >
      <Box sx={styles.mainContainer}>
        <Box
          onDragOver={(event) => handleDragOver(event)}
          onDrop={(event) => handleDrop(event)}
        >
          <UploadFileIcon sx={{ fontSize: 50, color: '#9CA3AF' }} />
          <Typography variant='body2' sx={{ color: '#6B7280' }}>
            Drag and drop your file(.docx) here or{' '}
          </Typography>
          <Button
            variant='text'
            onClick={() => inputRef.current.click()}
            sx={{ textTransform: 'none', color: '#3B82F6' }}
          >
            Browse
          </Button>
          <input
            type='file'
            hidden
            ref={inputRef}
            accept={filesAccepted.join(',')}
            onChange={uploadFiles}
          />
          {progress !== null && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '90%',
              }}
            >
              <Typography variant='caption' sx={{ color: '#6B7280', mb: 0.5 }}>
                Uploading...
              </Typography>
              <LinearProgress
                variant='determinate'
                value={progress}
                sx={{ height: '12px', borderRadius: '30px' }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* File List */}
      {files && files[0] && (
        <Box sx={styles.fileContainer}>
          <DocxIcon />
          <Box sx={{ flexGrow: 1, marginLeft: '6px' }}>
            <Typography
              variant='body1'
              sx={{ fontFamily: fonts.primary, fontSize: '14px' }}
            >
              {files[0].name}
            </Typography>
            <Typography
              variant='body2'
              sx={{
                fontFamily: fonts.primary,
                color: '#6B7280',
                fontSize: '12px',
              }}
            >
              {formatFileSize(files[0].size)}
            </Typography>
          </Box>
          <IconButton color='error' onClick={() => setFiles(null)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

FileUpload.propTypes = {
  filesAccepted: PropTypes.array,
  onError: PropTypes.func,
  files: PropTypes.object,
  setFiles: PropTypes.func,
};

const styles = {
  mainContainer: {
    border: '1px solid #E2E8F0',
    background: '#FFFFFF',
    borderRadius: '5px',
    width: '90%',
    height: '147px',
    padding: '16px 24px',
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },

  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #E2E8F0',
    background: '#FFFFFF',
    borderRadius: '5px',
    width: '90%',
    padding: '16px 24px',
    margin: '0 auto',
    fontFamily: fonts.primary,
  },
  progressContainer: (progress) => ({
    textAlign: 'center', // Center the content
    position: 'absolute',
    bottom: '15px',
    width: '97%',
    display: progress ? 'block' : 'none',
  }),
  chipContainer: (files) => ({
    display: files ? 'block' : 'none',
  }),
};

export default FileUpload;
