import React, { useEffect, useState } from 'react';
import { getCurrentAdmin } from '../services/AdminService';
import TopBar from '../components/top-bar/TopBar';
import PayRollTable from '../components/payroll/tables/PayRollTable';
import '../styles/Payroll.css';
import { Alert, Box, Snackbar, Grid, Button } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AttendanceUploadModal from '../components/payroll/modals/attendanceUpload';
import { styleProps } from '../../src/styles/styleProps';

const { colors } = styleProps;

const Payroll = () => {
  const [user, setUser] = useState('');
  const [attendanceUploadModal, setAttendanceUploadModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [snackbarVisibility, setSnackbarVisibility] = useState(false);
  const [dateCleared, setDateCleared] = useState(false);

  const handleClose = () => {
    setSnackbarVisibility(false);
  };

  const handleDateChange = (newDate) => {
    if (newDate && dayjs.isDayjs(newDate)) {
      setSelectedDate(newDate);
    }
  };

  const handleDateClear = () => {
    setSelectedDate(null);
    setDateCleared(true);
    setTimeout(() => {
      setDateCleared(false);
    }, 1500);
  };

  const getSelectedYear = () => {
    return selectedDate ? selectedDate.year() : null;
  };

  const getSelectedMonth = () => {
    return selectedDate ? selectedDate.month() + 1 : null; // Adding 1 since dayjs months are 0-based
  };

  const completeMessage = () => {
    setAttendanceUploadModal(false);
    setSnackbarVisibility(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setUser(await getCurrentAdmin());
  };

  return (
    <>
      <TopBar title='Payroll' user={user} />
      <Box
        sx={{
          height: 'calc(100vh - 100px)',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          paddingX: 3,
        }}
      >
        <Box sx={styles.filterStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container alignItems='center'>
              <Grid item>
                <DatePicker
                  views={['year', 'month']}
                  openTo='month'
                  value={selectedDate}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      size: 'small',
                      placeholder: 'Search payroll',
                    },
                    field: {
                      clearable: true,
                      onClear: handleDateClear,
                    },
                    inputAdornment: {
                      position: 'start',
                    },
                  }}
                  slots={{
                    openPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  sx={{
                    height: '48px',
                    maxWidth: '200px',
                  }}
                  className='custom-date-picker'
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Button
              variant='outlined'
              startIcon={<CloudUploadOutlinedIcon />}
              onClick={() => setAttendanceUploadModal(true)}
              sx={styles.buttonStyles}
            >
              Attendance Upload
            </Button>
            <Button
              variant='outlined'
              startIcon={<SettingsOutlinedIcon />}
              onClick={() => (window.location = '/payrollSetting')}
              sx={styles.buttonStyles}
            >
              Payroll Settings
            </Button>
            <Button
              variant='outlined'
              startIcon={<CalendarMonthOutlinedIcon />}
              onClick={() => (window.location.href = '/payrollSchedule')}
              sx={{
                backgroundColor: colors.btnPrimary,
                ...styles.buttonStyles,
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#2c7be8',
                },
              }}
            >
              Schedule Payroll
            </Button>
          </Box>
        </Box>

        <Box>
          <PayRollTable
            selectedYear={getSelectedYear()}
            selectedMonth={getSelectedMonth()}
          />
        </Box>
      </Box>

      <AttendanceUploadModal
        visible={attendanceUploadModal}
        setVisible={setAttendanceUploadModal}
        onFinish={completeMessage}
      />

      <Snackbar
        open={snackbarVisibility}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity='success'>
          Attendance details bulk uploaded successfully
        </Alert>
      </Snackbar>

      {dateCleared && (
        <Snackbar
          open={dateCleared}
          autoHideDuration={1500}
          onClose={() => setDateCleared(false)}
        >
          <Alert severity='success'>Date filter cleared!</Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Payroll;

const styles = {
  filterStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '3vh',
    gap: '24px',
  },

  buttonStyles: {
    borderRadius: '12px',
    textTransform: 'none',
    padding: '8px 16px',
    whiteSpace: 'nowrap',
    height: '48px',
    gap: '8px',
    borderColor: '#A0A0A6',
    color: '#2F2F33',
    '&:hover': {
      borderColor: '#A0A0A6',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
};
