import React from 'react';
import PropTypes from 'prop-types';
import NestedMenu from './NestedMenu';
import Menu from './Menu';
import { PAGES } from '../../../data/routeDefinition';

const MenuItem = ({ menuItem }) => {
  return menuItem.hasSubMenu ? (
    <NestedMenu groupMenu={menuItem}>
      {menuItem.subMenus.map((subItem) => (
        <Menu
          key={subItem.pageId}
          data={PAGES[subItem.pageId]}
          disableBackground={true}
        />
      ))}
    </NestedMenu>
  ) : (
    <Menu data={PAGES[menuItem.pageId]} />
  );
};

MenuItem.propTypes = {
  menuItem: PropTypes.shape({
    hasSubMenu: PropTypes.bool.isRequired,
    subMenus: PropTypes.arrayOf(
      PropTypes.shape({
        pageId: PropTypes.string.isRequired,
      }),
    ),
    group: PropTypes.string,
    pageId: PropTypes.string,
  }).isRequired,
};

export default MenuItem;
