/* eslint-disable react/prop-types */
import React from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  styled,
  Typography,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Breadcrumbs,
  Link,
} from '@mui/material';
import {
  ArrowBackIos as ArrowBackIosIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  PermIdentityOutlined as AccountIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import { styleProps } from '../../styles/styleProps';
import Emp from '../../../src/assets/images/businessman.png';
import { clearAuthData } from '../../services/AuthService';

const { fonts, fontSizes, colors } = styleProps;

const PAGES_WITHOUT_BREADCRUMBS = new Set([
  'dashboard',
  'payroll',
  'employees',
  'projects',
  'projectreports',
  'hybridschedule',
  'attendancesettings',
  'attendance',
  'admins',
  'profile',
  'offerlettertemplates',
  'offerletters',
  // Add more pages as needed
]);

const TopBar = ({ title, user, backEnabled, showBreadcrumbs = true }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();

  const pathnames = location.pathname
    .split('/')
    .filter((x) => x)
    .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToEmployee = () => {
    window.location = '/employees';
  };

  const logoutUser = async () => {
    try {
      const response = await axios.get('/api/user/logout', {
        headers: {
          Authorization: `"${localStorage.getItem('loggedInAuth')}"`,
        },
      });

      if (response.data.success === true) {
        clearAuthData();
        window.location = '/';
      }
    } catch (err) {
      console.error('Logout error:', err.message);
      window.location = '/error';
    }
  };

  const shouldShowBreadcrumbs =
    showBreadcrumbs &&
    !PAGES_WITHOUT_BREADCRUMBS.has(
      location.pathname.replace('/', '').toLowerCase(),
    );

  // Function to render breadcrumbs
  const renderBreadcrumbs = () => {
    if (!shouldShowBreadcrumbs) return null;

    return (
      <StyledBreadcrumbs
        separator={<NavigateNextIcon sx={{ fontSize: 16 }} />}
        aria-label='breadcrumb'
      >
        <BreadcrumbLink
          onClick={() => navigate('/dashboard')}
          sx={{ cursor: 'pointer' }}
        >
          Home
        </BreadcrumbLink>
        {pathnames.map((name, index) => {
          if (name.toLowerCase() === 'dashboard') return null;

          const routeTo = `/${pathnames
            .slice(0, index + 1)
            .join('/')
            .toLowerCase()}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <BreadcrumbText key={name}>{name}</BreadcrumbText>
          ) : (
            <BreadcrumbLink
              key={name}
              onClick={() => navigate(routeTo)}
              sx={{ cursor: 'pointer' }}
            >
              {name}
            </BreadcrumbLink>
          );
        })}
      </StyledBreadcrumbs>
    );
  };

  return (
    <TopbarContainer>
      <ContentContainer>
        <TextContainer showBreadcrumbs={shouldShowBreadcrumbs}>
          {backEnabled ? (
            <TitleText>
              <IconButton onClick={navigateToEmployee}>
                <ArrowBackIosIcon />
              </IconButton>
              {title}
            </TitleText>
          ) : (
            <TitleText>{title}</TitleText>
          )}
          {renderBreadcrumbs()}
        </TextContainer>

        <ProfileContainer>
          <Avatar
            alt={user?.adminName}
            src={Emp}
            sx={{ width: '48px', height: '48px', borderRadius: '120px' }}
          />
          <ProfileInfo>
            {user && <UserNameText>{user.adminName}</UserNameText>}
            {user && <UserEmailText>{user.adminEmail}</UserEmailText>}
          </ProfileInfo>
          <StyledIconButton
            onClick={handleClick}
            aria-controls={open ? 'profile-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </StyledIconButton>
        </ProfileContainer>

        <Menu
          id='profile-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={menuSlotProps}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => navigate('/profile')}>
            <ListItemIcon>
              <AccountIcon
                sx={{ fontSize: 16, color: colors.textMediumGrey }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: colors.textMediumGrey,
                fontFamily: fonts.primary,
              }}
            >
              View Profile
            </Typography>
          </MenuItem>
          <MenuItem onClick={logoutUser}>
            <ListItemIcon>
              <LogoutOutlinedIcon
                sx={{ fontSize: 16, color: colors.textMediumGrey }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: colors.textMediumGrey,
                fontFamily: fonts.primary,
              }}
            >
              Log out
            </Typography>
          </MenuItem>
        </Menu>
      </ContentContainer>
    </TopbarContainer>
  );
};

export default TopBar;

// Menu styling props
const menuSlotProps = {
  paper: {
    elevation: 0,
    sx: {
      width: '160px',
      overflow: 'visible',
      filter: 'drop-shadow(0px 12px 32px rgba(0, 0, 0, 0.12))',
      mt: 1,
      border: '1px solid #F7F7F8',
      borderRadius: '8px',
      '& .MuiMenuItem-root': {
        padding: '10px 20px',
        gap: '0px',
      },
    },
  },
};

// Styled components
const TopbarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '80px',
  borderBottom: '1px solid #F1F1F2',
  padding: '4px 32px',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0px',
  gap: '20px',
  height: '72px',
});

const TextContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showBreadcrumbs',
})(({ showBreadcrumbs }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: showBreadcrumbs ? '4px' : '0px',
  height: showBreadcrumbs ? '60px' : 'auto',
  flex: '0 1 auto',
}));

const ProfileContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '12px 16px',
  height: '72px',
  flex: '0 1 auto',
});

const ProfileInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: 43,
  gap: 4,
});

const UserNameText = styled(Typography)({
  fontSize: fontSizes.normal,
  fontWeight: 500,
  color: colors.textMediumGrey,
  lineHeight: '17px',
  fontFamily: fonts.primary,
});

const UserEmailText = styled(Typography)({
  fontSize: fontSizes.normal,
  fontWeight: 400,
  color: colors.textLightGrey,
  lineHeight: '22px',
  fontFamily: fonts.primary,
});

const TitleText = styled(Typography)({
  fontSize: fontSizes.header,
  fontWeight: 500,
  height: '34px',
  lineHeight: '34px',
  color: colors.black,
  fontFamily: fonts.primary,
});

const StyledIconButton = styled(IconButton)({
  padding: 0,
  '& svg': {
    width: '24px',
    height: '24px',
    color: colors.textLightGrey,
  },
});

const StyledBreadcrumbs = styled(Breadcrumbs)({
  '& .MuiBreadcrumbs-separator': {
    margin: '0 8px',
    color: colors.textLightGrey,
  },
  '& .MuiBreadcrumbs-li': {
    display: 'flex',
    alignItems: 'center',
  },
});

const BreadcrumbLink = styled(Link)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '22px',
  color: colors.textMediumGrey,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

const BreadcrumbText = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '22px',
  color: '#8D8D95',
});
