import { Box, Grid } from '@mui/material';
import React from 'react';
import BackgroundImage from '../../assets/images/img_nimikash_background.svg';
import LogoWhite from '../../assets/images/img_nimikash_logo_white.png';
//Styles and styled components
const backgroundStyles = {
  minHeight: '100vh',
  background: `linear-gradient(0deg, rgba(6, 102, 235, 0.3), rgba(6, 102, 235, 0.3)), url(${BackgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};
const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
// eslint-disable-next-line react/prop-types
export default function GenericSection({ children }) {
  return (
    <div style={backgroundStyles}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            height: '20vh',
            ...flexStyle,
            mb: 8,
          }}
        >
          <Box sx={flexStyle}>
            <img src={LogoWhite} alt='logo-white' width='250px' />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            ...flexStyle,
            alignItems: 'flex-start',
            marginTop: -10,
          }}
        >
          <Grid
            item
            xs={9}
            md={5}
            lg={4}
            sx={{
              height: 'auto',
              marginBottom: 10,
            }}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
