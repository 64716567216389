/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Paper,
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TableContainer,
  Stack,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { updateEmployeeDetails } from '../../../services/EmployeeService';
import { EmployeeType } from '../../../enums/Employee';
import { hourlyRate } from '../../../data/genericConstants';
import { getCountryCodeFromMobileNumber } from '../../../utils/commonUtils';
import { PAY_STUB_STATUS } from '../../../enums/Paystub';
import { paginate } from '../../../utils/PaginationUtils';
import { ITEMS_PER_PAGE } from '../../../constants/PaginationCount';

const TableComponent = ({
  columns,
  data,
  selectedEmployees,
  setSelectedEmployees,
  setUpdateTableData,
}) => {
  const [hoursWorked, setHoursWorked] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [page, setPage] = useState(1);

  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.employeeDisplayId);
      setSelectedEmployees(new Set(newSelecteds));
      return;
    }
    setSelectedEmployees(new Set());
  };

  const handleCheckboxClick = (employeeDisplayId) => {
    const isChecked = selectedEmployees.has(employeeDisplayId);
    let newSelected = new Set(selectedEmployees);

    if (isChecked) {
      newSelected.delete(employeeDisplayId);
    } else {
      newSelected.add(employeeDisplayId);
    }
    setSelectedEmployees(newSelected);
  };

  const handleOpenDialog = (consultant) => {
    setSelectedConsultant(consultant);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleHoursChange = (event) => {
    const value = event.target.value;
    setHoursWorked({
      ...hoursWorked,
      [selectedConsultant.employeeDisplayId]: value,
    });
  };

  const handleSave = async () => {
    await updateConsultantSalary();
    setUpdateTableData(Date.now());
    setOpenDialog(false);
  };

  // Use the pagination utility
  const { paginatedData, totalPages } = paginate(data, page, ITEMS_PER_PAGE);

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const convertPaystubEnums = (status) => {
    if (status === PAY_STUB_STATUS.PAYMENT_FAILED) {
      return 'Payment Failed';
    }
    return 'Un Paid';
  };

  const updateConsultantSalary = async () => {
    const updatedSalary =
      hoursWorked[selectedConsultant.employeeDisplayId] * hourlyRate;

    const countryCode = getCountryCodeFromMobileNumber(
      selectedConsultant.employeeMobile,
    );

    const body = {
      addressOne: selectedConsultant.employeeAddressLineOne,
      addressTwo: selectedConsultant.employeeAddressLineTwo,
      city: selectedConsultant.employeeCity,
      country: countryCode.name || 'LK',
      currency: selectedConsultant.Currency.currencyDisplayId,
      designation: selectedConsultant.employeeDesignation,
      email: selectedConsultant.employeeWorkEmail,
      empCountry: selectedConsultant.employeeCountry,
      employeeEmployeeId: selectedConsultant.employeeEmployeeId,
      firstName: selectedConsultant.employeeFirstName,
      lastName: selectedConsultant.employeeLastName,
      mobile: selectedConsultant.employeeMobile,
      nic: selectedConsultant.employeeNic,
      postal: selectedConsultant.employeePostalCode,
      salary: updatedSalary,
      state: selectedConsultant.employeeState,
      type: selectedConsultant.employeeType,
    };
    await updateEmployeeDetails(body);
  };

  return (
    <Box sx={{ marginBottom: '100px' }}>
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell sx={{ width: 10 }}>
                <StyledCheckbox
                  onChange={handleSelectAllClick}
                  checked={
                    data.length > 0 && selectedEmployees.size === data.length
                  }
                />
              </TableCell>
              {columns.map((headerName, index) => (
                <TableCell key={index} align='left'>
                  {headerName}
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {paginatedData.map((data, index) => (
              <React.Fragment key={index}>
                <StyledTableRow
                  consultant={data.employeeType === EmployeeType.Consultant}
                >
                  <TableCell>
                    <StyledCheckbox
                      checked={selectedEmployees.has(data.employeeDisplayId)}
                      onChange={() =>
                        handleCheckboxClick(data.employeeDisplayId)
                      }
                    />
                  </TableCell>
                  <TableCell>{data.employeeEmployeeId}</TableCell>
                  <TableCell>
                    {(data.employeeFirstName ?? '') +
                      ' ' +
                      (data.employeeLastName ?? '')}
                  </TableCell>
                  <TableCell>{data.employeeWorkEmail}</TableCell>
                  <TableCell>{data.employeeDesignation}</TableCell>
                  <TableCell>
                    {data.employeeType === EmployeeType.Consultant ? (
                      <div>
                        {data.employeeSalary > 0 ? (
                          <SalaryButton onClick={() => handleOpenDialog(data)}>
                            {data.Currency.currencyCode}{' '}
                            {nf.format(data.employeeSalary)}
                          </SalaryButton>
                        ) : (
                          <ActionButton onClick={() => handleOpenDialog(data)}>
                            Add Hours
                          </ActionButton>
                        )}
                      </div>
                    ) : (
                      `${data.Currency.currencyCode} ${nf.format(
                        data.employeeSalary,
                      )}`
                    )}
                  </TableCell>
                  <TableCell>{data.employeeMobile}</TableCell>
                  <TableCell>
                    {convertPaystubEnums(data.PayStubs[0].status)}
                  </TableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      <Stack spacing={2} alignItems='flex-end' sx={{ mt: 3, mb: 3 }}>
        <StyledPagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          shape='rounded'
        />
      </Stack>

      <StyledDialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {hoursWorked[selectedConsultant?.employeeDisplayId]
            ? 'Edit Hours'
            : 'Enter Hours'}
        </DialogTitle>
        <DialogContent>
          <TextField
            type='number'
            placeholder='Worked hours'
            value={hoursWorked[selectedConsultant?.employeeDisplayId] || ''}
            onChange={handleHoursChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <ActionButton onClick={handleSave} variant='contained'>
            Save
          </ActionButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
};

// Styled Components
const StyledTableContainer = styled(TableContainer)({
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
});

const StyledTable = styled(Table)({
  minWidth: '1096px',
});

const StyledTableHead = styled(TableHead)({
  '& .MuiTableCell-head': {
    backgroundColor: '#F7F7F8',
    color: '#505057',
    fontWeight: 500,
    fontSize: '14px',
    padding: '12px 24px',
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
    },
    '&:last-child': {
      borderTopRightRadius: '8px',
    },
  },
});

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'consultant',
})(({ consultant }) => ({
  backgroundColor: consultant ? '#EBF5FF' : 'none',
  '&:hover': {
    backgroundColor: consultant ? '#D6E6FF' : '#F5F7F8',
  },
  '& .MuiTableCell-body': {
    color: '#2D3A44',
    fontSize: '14px',
    padding: '16px 24px',
    maxHeight: '72px',
  },
}));

const StyledCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
  '&.Mui-checked': {
    color: '#059669',
  },
});

const ActionButton = styled(Button)({
  minWidth: '90px',
  height: '36px',
  borderRadius: '4px',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '14px',
  padding: '8px 16px',
  color: '#fff',
});

const SalaryButton = styled(Button)({
  color: '#007BFF',
  textAlign: 'left',
  textTransform: 'none',
  padding: '0',

  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '10px',
  },
  '& .MuiDialogTitle-root': {
    padding: '20px',
  },
  '& .MuiDialogContent-root': {
    padding: '20px',
  },
  '& .MuiDialogActions-root': {
    padding: '20px',
  },
});

const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    color: '#505057',
    height: '40px',
    width: '40px',
    '&.Mui-selected': {
      backgroundColor: '#E6F0FD',
    },
  },
});

export default TableComponent;
