import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  Stack,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  deletePayroll,
  getAllPayrolls,
  getAllPayRollsByYearAndMonth,
} from '../../../services/PayRollService';
import { PayRollStatus } from '../../../enums/PayRollStatus';
import PayrollDetailsDialog from '../modals/PayrollDetailsDialog';
import { paginate } from '../../../utils/PaginationUtils';
import { ITEMS_PER_PAGE } from '../../../constants/PaginationCount';

const PayrollTable = ({ selectedYear, selectedMonth }) => {
  const [payrolls, setPayrolls] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  useEffect(() => {
    fetchPayrolls();
  }, [selectedYear, selectedMonth]);

  const fetchPayrolls = async () => {
    try {
      const response =
        selectedYear && selectedMonth
          ? await getAllPayRollsByYearAndMonth(selectedYear, selectedMonth)
          : await getAllPayrolls();

      setPayrolls(response.message);
    } catch (error) {
      console.error('Error fetching payrolls:', error);
    }
  };

  const handleEditClick = (displayId) => {
    window.location = `/payrollSchedule/${displayId}`;
  };

  const handleViewClick = (payroll) => {
    setSelectedPayroll(payroll);
    setIsViewModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsViewModalOpen(false);
    setSelectedPayroll(null);
  };

  const handleDelete = async () => {
    try {
      if (selectedPayroll?.displayId) {
        await deletePayroll(selectedPayroll.displayId);
        fetchPayrolls();
        handleCloseModal();
      }
    } catch (error) {
      console.error('Error deleting payroll:', error);
    }
  };

  // Use the pagination utility
  const { paginatedData, totalPages } = paginate(
    payrolls,
    page,
    ITEMS_PER_PAGE,
  );

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const formatDate = (date) => {
    return format(new Date(date), 'dd MMM yyyy');
  };

  const translateStatus = (statusKey) => {
    const statusMap = {
      D: PayRollStatus.D,
      P: PayRollStatus.P,
      PP: PayRollStatus.PP,
      S: PayRollStatus.S,
    };
    return statusMap[statusKey] || statusKey;
  };

  const getActionButtons = (status, payrollId, payroll) => {
    const statusActions = {
      Scheduled: (
        <ActionButton onClick={() => handleEditClick(payrollId)}>
          Edit
        </ActionButton>
      ),
      Draft: (
        <ActionButton onClick={() => handleEditClick(payrollId)}>
          Edit
        </ActionButton>
      ),
      Paid: (
        <ActionButton onClick={() => handleViewClick(payroll)}>
          View
        </ActionButton>
      ),
      'Partialy Paid': (
        <ActionButton onClick={() => handleViewClick(payroll)}>
          View
        </ActionButton>
      ),
    };

    return statusActions[status] || <Button>Unknown</Button>;
  };

  return (
    <>
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell>Pay runs</TableCell>
              <TableCell>Pay Date</TableCell>
              <TableCell>Employees</TableCell>
              <TableCell>Total Payment (LKR)</TableCell>
              <TableCell>Total Payment (USD)</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align='center'>Action</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {paginatedData.map((payroll) => (
              <StyledTableRow key={payroll.id}>
                <TableCell>
                  {`${formatDate(payroll.startDate)} - ${formatDate(
                    payroll.endDate,
                  )}`}
                </TableCell>
                <TableCell>{formatDate(payroll.payDate)}</TableCell>
                <TableCell>{payroll.noEmployees}</TableCell>
                <TableCell>{nf.format(payroll.lkrAmount)}</TableCell>
                <TableCell>{nf.format(payroll.usdAmount)}</TableCell>
                <TableCell>{translateStatus(payroll.status)}</TableCell>
                <TableCell>
                  <Stack direction='row' spacing={1} justifyContent='center'>
                    {getActionButtons(
                      translateStatus(payroll.status),
                      payroll.displayId,
                      payroll,
                    )}
                  </Stack>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      <Stack spacing={2} alignItems='flex-end' sx={{ mt: 3, mb: 3 }}>
        <StyledPagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          shape='rounded'
          sx={{
            '& .MuiPaginationItem-root': {
              color: '#505057',
              height: '40px',
              width: '40px',
              '&.Mui-selected': {
                backgroundColor: '#E6F0FD',
              },
            },
          }}
        />
      </Stack>

      {selectedPayroll && (
        <PayrollDetailsDialog
          open={isViewModalOpen}
          payroll={selectedPayroll}
          onClose={handleCloseModal}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

PayrollTable.propTypes = {
  selectedYear: PropTypes.string,
  selectedMonth: PropTypes.string,
};

export default PayrollTable;

// Styled Components
const StyledTableContainer = styled(TableContainer)({
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
  marginBottom: '24px',
});

const StyledTable = styled(Table)({
  minWidth: '1096px',
});

const StyledTableHead = styled(TableHead)({
  '& .MuiTableCell-head': {
    backgroundColor: '#F7F7F8',
    color: '#505057',
    fontWeight: 500,
    fontSize: '14px',
    padding: '12px 24px',
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
    },
    '&:last-child': {
      borderTopRightRadius: '8px',
    },
  },
});

const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: '#F5F7F8',
  },
  '& .MuiTableCell-body': {
    color: '#2D3A44',
    fontSize: '14px',
    padding: '16px 24px',
    maxHeight: '72px',
  },
});

const ActionButton = styled(Button)({
  minWidth: '90px',
  height: '36px',
  borderRadius: '4px',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '14px',
  padding: '8px 16px',
  color: '#0666EB',
});

const StyledPagination = styled(Pagination)({
  '& .MuiPagination-ul': {
    justifyContent: 'center',
  },
  '& .MuiPaginationItem-root': {
    margin: '0 4px',
    borderRadius: '4px',
  },
});
