import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const TextFieldWithProps = ({ inputField, formik, ...rest }) => {
  const handle = inputField?.placeholder;
  return (
    <TextField
      {...rest}
      {...defaultextFieldProps}
      label={inputField?.label}
      name={handle}
      value={formik.values[handle]}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={formik.touched[handle] && Boolean(formik.errors[handle])}
      helperText={formik.touched[handle] && formik.errors[handle]}
    />
  );
};

TextFieldWithProps.propTypes = {
  inputField: PropTypes.object,
  formik: PropTypes.object,
};

const defaultextFieldProps = {
  fullWidth: true,
  margin: 'normal',
  sx: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '12px',
    },
    '& .MuiInput-root': {
      borderRadius: '12px',
    },
  },
};

export { TextFieldWithProps, defaultextFieldProps };
