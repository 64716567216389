/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import { styleProps } from '../../../styles/styleProps';

const { fonts } = styleProps;

export default function EditModal({
  title,
  data,
  fieldId,
  visible,
  onChange,
  handleClose,
  heading,
}) {
  const [value, setValue] = useState(data[fieldId] || 0);

  useEffect(() => {
    setValue(data[fieldId] || 0);
  }, [data, fieldId, visible]);

  return (
    <Modal open={visible} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontFamily: fonts.primary,
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '24px',
              color: '#2F2F33',
              alignSelf: 'flex-start',
            }}
          >
            {heading}
          </Typography>
          <FormControl fullWidth>
            <InputLabel
              shrink
              htmlFor='bootstrap-input'
              sx={{
                color: '#505057',
                fontWeight: 400,
                position: 'absolute',
                transform: 'translateY(-50%)',
              }}
            >
              {title}
            </InputLabel>
            <BootstrapInput
              id='bootstrap-input'
              pla
              value={value}
              onChange={(e) => setValue(e.target.value)}
              type='number'
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: '16px',
            gap: '12px',
          }}
        >
          <Button
            variant='outlined'
            sx={{
              borderRadius: '8px',
              fontWeight: 500,
              color: '#344054',
              border: '1px solid #D0D5DD',
              width: '352px',
              height: '48px',
              backgroundColor: '#FFFFFF',
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            sx={{
              borderRadius: '8px',
              fontWeight: 500,
              backgroundColor: '#0666EB',
              color: '#FFFFFF',
              width: '352px',
              height: '48px',
              '&:hover': {
                backgroundColor: '#2563EB',
              },
            }}
            onClick={() => {
              onChange(value ? parseFloat(value) : parseFloat(0));
              handleClose();
            }}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFFFFF',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  gap: '32px',
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    fontSize: 16,
    padding: '10px 14px',
  },
}));
