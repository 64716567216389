import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
  Divider,
  Stack,
} from '@mui/material';
import { format } from 'date-fns';
import {
  Delete as DeleteIcon,
  CalendarMonth as CalendarIcon,
  PeopleAlt as PeopleIcon,
  CreditCard as PaymentIcon,
  Flag as StatusIcon,
} from '@mui/icons-material';
import StatusChangeConfirmationModal from '../../alerts/StatusChangeConfirmationModal';
import { PayRollStatus } from '../../../enums/PayRollStatus';

const PayrollDetailsDialog = ({ open, payroll, onClose, onDelete }) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const nf = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });

  const formatDate = (date) => format(new Date(date), 'dd MMM yyyy');

  const handleDeleteClick = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    onDelete(payroll);
    setIsDeleteConfirmationOpen(false);
    onClose();
  };

  const handleCloseDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'processing':
        return 'info';
      default:
        return 'default';
    }
  };

  const translateStatus = (statusKey) => {
    const statusMap = {
      D: PayRollStatus.D,
      P: PayRollStatus.P,
      PP: PayRollStatus.PP,
      S: PayRollStatus.S,
    };
    return statusMap[statusKey] || statusKey;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
          },
        }}
      >
        <DialogTitle>
          <Typography variant='h5' fontWeight='bold'>
            Payroll Details
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <Box display='flex' alignItems='center' gap={2}>
              <CalendarIcon color='primary' />
              <Typography variant='body1'>
                Pay Run:{' '}
                {`${formatDate(payroll.startDate)} - ${formatDate(
                  payroll.endDate,
                )}`}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={2}>
              <CalendarIcon color='primary' />
              <Typography variant='body1'>
                Pay Date: {formatDate(payroll.payDate)}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={2}>
              <PeopleIcon color='primary' />
              <Typography variant='body1'>
                Employees: {payroll.noEmployees}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={2}>
              <PaymentIcon color='primary' />
              <Typography variant='body1'>
                Total Payment (LKR): {nf.format(payroll.lkrAmount)}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={2}>
              <PaymentIcon color='primary' />
              <Typography variant='body1'>
                Total Payment (USD): {nf.format(payroll.usdAmount)}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={2}>
              <StatusIcon color='primary' />
              <Chip
                label={translateStatus(payroll.status)}
                color={getStatusColor(payroll.status)}
                variant='outlined'
                size='small'
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={onClose} variant='outlined' color='primary'>
            Close
          </Button>
          <Button
            onClick={handleDeleteClick}
            variant='contained'
            color='error'
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {isDeleteConfirmationOpen && (
        <StatusChangeConfirmationModal
          modalStatus={isDeleteConfirmationOpen}
          confirm={handleConfirmDelete}
          reject={handleCloseDeleteConfirmation}
          type='Delete'
          text='Are you sure you want to delete this payroll?'
          title='Delete Payroll'
        />
      )}
    </>
  );
};

PayrollDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  payroll: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PayrollDetailsDialog;
