import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Collapse, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { styleProps } from '../../../styles/styleProps';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const { fonts, colors } = styleProps;

const NestedMenu = ({ groupMenu, children }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <MenuWrapper>
      <MenuButton onClick={handleClick}>
        <ItemWrapper>
          {groupMenu.icon && (
            <groupMenu.icon
              sx={{
                fontSize: '24px',
                color: colors.textMediumGrey,
              }}
            />
          )}
          <ItemText>{groupMenu.group}</ItemText>
        </ItemWrapper>
        <ExpandMoreIcon
          style={{
            transform: open ? 'rotate(180deg)' : 'rotate(0)',
            color: colors.textMediumGrey,
          }}
        />
      </MenuButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <MenuContent>
          {React.Children.map(children, (child, index) => {
            const isSelected = child.props?.data?.to === location.pathname;
            return (
              <React.Fragment key={index}>
                <MenuItem isselected={isSelected}>{child}</MenuItem>
                <StyledDivider />
              </React.Fragment>
            );
          })}
        </MenuContent>
      </Collapse>
    </MenuWrapper>
  );
};

NestedMenu.propTypes = {
  groupMenu: PropTypes.shape({
    group: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
  }).isRequired,
  children: PropTypes.node,
};

export default NestedMenu;

const MenuWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const MenuButton = styled(Box)(({ isactive, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxHeight: '40px',
  padding: '8px 12px',
  borderRadius: theme.shape.borderRadius,
  background: isactive ? '#F2F7FD' : 'transparent',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isactive ? 'none' : '#F2F7FD',
  },
}));

const ItemText = styled(Typography)(({ isactive }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  fontFamily: fonts.primary,
  color: isactive ? colors.btnPrimary : colors.textMediumGrey,
}));

const ItemWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const MenuContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '202px',
  padding: '16px',
});

const StyledDivider = styled(Divider)({
  marginLeft: '20px',
  width: '90%',
  backgroundColor: colors.divider,
  border: '1px solid #F1F1F2',
});

const MenuItem = styled(Box)(({ isselected }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '24px',
  maxWidth: '202px',
  backgroundColor: 'transparent',
  '& .bullet': {
    width: '8px',
    height: '8px',
    background: isselected ? colors.white : colors.white,
    border: `1px solid ${
      isselected ? colors.btnPrimary : colors.textMediumGrey
    }`,
    borderRadius: '50%',
  },
  '& .text': {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: isselected ? colors.btnPrimary : colors.textMediumGrey,
  },
}));
